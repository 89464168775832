import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { ConcomitantMedicationBaseline, ConcomitantMedicationOnStudy, ConcomitantMedications as ConcomitantMedicationsDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';


declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IConcomitantMedicationsProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const medicalHistoryConMedsColumns: IGroupedSubformGridCell<ConcomitantMedicationBaseline>[] = [
    {
        name: 'genericMedicationName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.genericMedicationName
    },
    {
        name: 'indication',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="indicationSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'started',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.started ? DateTime.fromFormat(row.started.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'stopped',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.stopped ? DateTime.fromFormat(row.stopped.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'ongoing',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.ongoing
    }
];

const onStudyConMedsColumns: IGroupedSubformGridCell<ConcomitantMedicationOnStudy>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'genericMedicationName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.genericMedicationName
    },
    {
        name: 'indication',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="indicationSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'started',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.started
    },
    {
        name: 'stopped',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.stopped
    },
    {
        name: 'ongoing',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.ongoing
    }
];

const hasNoMedicalHistoryConMeds: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.baselineConcomitantMedications || value?.baselineConcomitantMedications?.length === 0;
const hasMedicalHistoryConMeds: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.baselineConcomitantMedications && value?.baselineConcomitantMedications?.length > 0;

const hasNoOnStudyConMeds: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.onStudyConcomitantMedications || value?.onStudyConcomitantMedications?.length === 0;
const hasOnStudyConMeds: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.onStudyConcomitantMedications && value?.onStudyConcomitantMedications?.length > 0;

const conditionSubscription = { value: true };

const ConcomitantMedications: FunctionComponent<IConcomitantMedicationsProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Medical History
                    </Typography>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasMedicalHistoryConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ConcomitantMedicationBaseline}
                            name="baselineConcomitantMedications"
                            columns={medicalHistoryConMedsColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasNoMedicalHistoryConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Medical History - Concomitant Medications"
                                description="There is no medical history - concomitant medication associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        On Study
                    </Typography>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasOnStudyConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ConcomitantMedicationOnStudy}
                            name="onStudyConcomitantMedications"
                            columns={onStudyConMedsColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasNoOnStudyConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No On Study - Concomitant Medications"
                                description="There is no on study - concomitant medication associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default ConcomitantMedications;
