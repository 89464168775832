import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { Progression as ProgressionDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import FormDates from '../../components/form/FormDates';

interface IProgressionProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNoProgression: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => !value?.eventCrfId;
const hasProgression: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => !!value?.eventCrfId;

const isDetectionMethodOther: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.methodForDetecting?.toLowerCase().includes('specify') ?? false;
const isConfirmatoryAssessmentNo: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.wasAConfirmatory?.toLowerCase() === 'no';
const isConfirmatoryAssessmentNotNo: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.wasAConfirmatory?.toLowerCase() !== 'no';
const isConfirmationMethodOther: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.methodOfConfirmation?.toLowerCase().includes('specify') ?? false;
const isNewTreatmentYes: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.wasNewTreatment?.toLowerCase().includes('specify') ?? false;
const isConfirmedProgressionYes: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.haveConfirmedProgression?.toLowerCase() === 'yes';
const isCnsTherapyOther: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => value?.cnsDirectedTherapy?.toLowerCase() === 'other';

const conditionSubscription = { value: true };

const Progression: FunctionComponent<IProgressionProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <Condition
                    type={ProgressionDto}
                    condition={hasProgression}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="dateOfProgression"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedFields>
                        <GroupedField
                            name="methodForDetecting"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={ProgressionDto}
                            condition={isDetectionMethodOther}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="methodForDetectingSpec"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <GroupedField
                            name="wasAConfirmatory"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={ProgressionDto}
                            condition={isConfirmatoryAssessmentNo}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="wasAConfirmatoryReason"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                        <Condition
                            type={ProgressionDto}
                            condition={isConfirmatoryAssessmentNotNo}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="dateOfConfirmation"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="methodOfConfirmation"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <Condition
                                type={ProgressionDto}
                                condition={isConfirmationMethodOther}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="methodOfConfirmationSpec"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <GroupedField
                            name="wasNewTreatment"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={ProgressionDto}
                            condition={isNewTreatmentYes}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="wasNewTreatmentSpec"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <GroupedField
                            name="haveConfirmedProgression"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={ProgressionDto}
                            condition={isConfirmedProgressionYes}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="cnsDirectedTherapy"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                    </GroupedFields>
                </Condition>
                <Condition
                    type={ProgressionDto}
                    condition={hasNoProgression}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Progression Form"
                            description="There is no progression form associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Progression;
