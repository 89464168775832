import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, getParentPropertyPath } from '@ngt/forms';
import { NewLesion, OverallResponse, TargetMeasurableLesion, NonMeasurableLesion, TumourAssessments as TumourAssessmentsDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import ValueWithSpecificationDisplay from './input/display/ValueWithSpecificationDisplay';
import { get } from 'lodash';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface ITumourAssessmentsProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    table: {               
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        },
        '& .MuiTable-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiTableHead-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiTableBody-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiTableRow-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiTableCell-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiButton-root': {
            fontSize: `0.75rem !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const hasTargetLesionResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {}, parentFieldName }) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: TargetMeasurableLesion = get(value, parentPropertyPath);

    if (!row) {
        return false;
    }

    //return row.lesion1Measurement != null && row.timepoint != 'Baseline';
    return row.timepoint != 'Baseline';
};

const hasNonTargetLesionResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {}, parentFieldName }) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: NonMeasurableLesion = get(value, parentPropertyPath);

    if (!row) {
        return false;
    }

    //return row.lesion1Status != null && row.timepoint != 'Baseline';
    return row.timepoint != 'Baseline';
};

// const targetMeasurableLesionColumns: IGroupedSubformGridCell<TargetMeasurableLesion>[] = [
//     {
//         name: 'timepoint',
//         content: (
//             <Input
//                 component={OpenClinicaFormDisplay}
//                 openClinicaUrl={openClinicaUrl}
//                 eventCrfIdName="eventCrfId"
//                 studyEventIdName="studyEventId"
//                 btnMinWidth="140px"
//                 eventCrfIdPrependParentName
//                 eventCrfIdTableCell
//                 studyEventIdPrependParentName
//                 studyEventIdTableCell
//                 textValueButton
//             />
//         ),
//         sortValue: (row) => row?.timepoint,
//         width: 200
//     },
//     {
//         name: 'notDoneReason',
//         content: (
//             <Input
//                 component={ValueWithReasonAndSpecificationDisplay}
//                 reasonName="notDoneReasonSpecify"
//                 reasonPrependParentName
//                 reasonTableCell
//             />
//         ),
//         sortValue: (row) => row?.notDoneReason,
//         width: 100
//     },
//     {
//         name: 'lesion1Measurement',
//         content: (
//             <Input
//                 component={NumericDisplay}
//                 suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
//             />
//         ),
//         sortValue: (row) => row?.lesion1Measurement
//     },
//     {
//         name: 'lesion2Measurement',
//         content: (
//             <Input
//                 component={NumericDisplay}
//                 suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
//             />
//         ),
//         sortValue: (row) => row?.lesion2Measurement
//     },
//     {
//         name: 'lesion3Measurement',
//         content: (
//             <Input
//                 component={NumericDisplay}
//                 suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
//             />
//         ),
//         sortValue: (row) => row?.lesion3Measurement
//     },
//     {
//         name: 'lesion4Measurement',
//         content: (
//             <Input
//                 component={NumericDisplay}
//                 suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
//             />
//         ),
//         sortValue: (row) => row?.lesion4Measurement
//     },
//     {
//         name: 'lesion5Measurement',
//         content: (
//             <Input
//                 component={NumericDisplay}
//                 suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
//             />
//         ),
//         sortValue: (row) => row?.lesion5Measurement
//     },
//     {
//         name: 'sumOfAllTargetLesions',
//         content: (
//             <Input
//                 component={NumericDisplay}
//                 suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
//             />
//         ),
//         sortValue: (row) => row?.sumOfAllTargetLesions
//     },
//     {
//         name: 'targetLesionResponse',
//         content: (
//             <Condition
//                 type={TumourAssessmentsDto}
//                 condition={hasTargetLesionResponse}
//                 subscription={conditionSubscription}
//                 mode={ConditionMode.Show}
//             >
//                 <Input
//                     component={IfElseDisplay}
//                     ifName="targetLesionResponse"
//                     elseName="targetLesionResponse"
//                     elseAccessor={(value: string) => "Missing"}
//                     condition={(value: string) => (value?.length ?? 0) > 0}
//                     ifPrependParentName={true}
//                     ifTableCell={true}
//                 />
//             </Condition>
//         ),
//         sortValue: (row) => row?.targetLesionResponse
//     }
// ];

// const nonMeasurableLesionColumns: IGroupedSubformGridCell<NonMeasurableLesion>[] = [
//     {
//         name: 'timepoint',
//         content: (
//             <Input
//                 component={OpenClinicaFormDisplay}
//                 openClinicaUrl={openClinicaUrl}
//                 eventCrfIdName="eventCrfId"
//                 studyEventIdName="studyEventId"
//                 btnMinWidth="140px"
//                 eventCrfIdPrependParentName
//                 eventCrfIdTableCell
//                 studyEventIdPrependParentName
//                 studyEventIdTableCell
//                 textValueButton
//             />
//         ),
//         sortValue: (row) => row?.timepoint,
//         width: 200
//     },
//     {
//         name: 'notDoneReason',
//         content: (
//             <Input
//                 component={ValueWithReasonAndSpecificationDisplay}
//                 reasonName="notDoneReasonSpecify"
//                 reasonPrependParentName
//                 reasonTableCell
//             />
//         ),
//         sortValue: (row) => row?.notDoneReason,
//         width: 100
//     },
//     {
//         name: 'lesion1Status',
//         content: (
//             <Input component={TextDisplay} />
//         ),
//         sortValue: (row) => row?.lesion1Status
//     },
//     {
//         name: 'lesion2Status',
//         content: (
//             <Input component={TextDisplay} />
//         ),
//         sortValue: (row) => row?.lesion2Status
//     },
//     {
//         name: 'lesion3Status',
//         content: (
//             <Input component={TextDisplay} />
//         ),
//         sortValue: (row) => row?.lesion3Status
//     },
//     {
//         name: 'lesion4Status',
//         content: (
//             <Input component={TextDisplay} />
//         ),
//         sortValue: (row) => row?.lesion4Status
//     },
//     {
//         name: 'lesion5Status',
//         content: (
//             <Input component={TextDisplay} />
//         ),
//         sortValue: (row) => row?.lesion5Status
//     },
//     {
//         name: 'nonTargetLesionResponse',
//         content: (
//             <Condition
//                 type={TumourAssessmentsDto}
//                 condition={hasNonTargetLesionResponse}
//                 subscription={conditionSubscription}
//                 mode={ConditionMode.Show}
//             >
//                 <Input
//                     component={IfElseDisplay}
//                     ifName="nonTargetLesionResponse"
//                     elseName="nonTargetLesionResponse"
//                     elseAccessor={(value: string) => "Missing"}
//                     condition={(value: string) => (value?.length ?? 0) > 0}
//                     ifPrependParentName={true}
//                     ifTableCell={true}
//                 />
//             </Condition>
//         ),
//         sortValue: (row) => row?.nonTargetLesionResponse
//     }
// ];

const newLesionColumns: IGroupedSubformGridCell<NewLesion>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'lesionNumber',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesionNumber
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.date   
    },
    {
        name: 'methodOfMeasurement',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="methodOfMeasurementSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.methodOfMeasurement
    },
    {
        name: 'site',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="siteSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'locationWithinOrgan',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.locationWithinOrgan
    }
];

const overallResponseColumns: IGroupedSubformGridCell<OverallResponse>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'assessmentDate',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.earliestAssessmentDate
    },
    {
        name: 'response',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.response
    }
];

const hasNoTargetMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.targetMeasurableLesions || value?.targetMeasurableLesions?.length === 0;
const hasTargetMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.targetMeasurableLesions && value?.targetMeasurableLesions?.length > 0;

const hasNoNonMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.nonMeasurableLesions || value?.nonMeasurableLesions?.length === 0;
const hasNonMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.nonMeasurableLesions && value?.nonMeasurableLesions?.length > 0;

const hasNoNewLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.newLesions || value?.newLesions?.length === 0;
const hasNewLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.newLesions && value?.newLesions?.length > 0;

const hasNoOverallResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.overallResponses || value?.overallResponses?.length === 0;
const hasOverallResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.overallResponses && value?.overallResponses?.length > 0;

const lesionsub = {
    value: true
}

const TumourAssessments: FunctionComponent<ITumourAssessmentsProps> = () => {
    const {classes} = useStyles();


    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <TumourAssessmentsInternal />
            </ContextMedicalReviewForm>
        </>
    );
};

const TumourAssessmentsInternal: FunctionComponent<ITumourAssessmentsProps> = () => {
    const {classes} = useStyles();

    const {state: { value: targetMeasurableLesions }} = useScopedField<TargetMeasurableLesion[]>('targetMeasurableLesions', lesionsub);

    const presentTargetLesions = useMemo(() => {
        const output = {
            lesion1: !!targetMeasurableLesions?.find(x => x.lesion1Measurement !== null && x.lesion1Measurement !== undefined),
            lesion2: !!targetMeasurableLesions?.find(x => x.lesion2Measurement !== null && x.lesion2Measurement !== undefined),
            lesion3: !!targetMeasurableLesions?.find(x => x.lesion3Measurement !== null && x.lesion3Measurement !== undefined),
            lesion4: !!targetMeasurableLesions?.find(x => x.lesion4Measurement !== null && x.lesion4Measurement !== undefined),
            lesion5: !!targetMeasurableLesions?.find(x => x.lesion5Measurement !== null && x.lesion5Measurement !== undefined),
            count: 0,
            columnWidth: 0
        };

        if (output.lesion1) {
            output.count++;
        }

        if (output.lesion2) {
            output.count++;
        }

        if (output.lesion3) {
            output.count++;
        }

        if (output.lesion4) {
            output.count++;
        }

        if (output.lesion5) {
            output.count++;
        }

        output.columnWidth = output.count === 0 ? 0 : (58 / output.count);

        return output
    }, [targetMeasurableLesions]);

    const targetMeasurableLesionColumns = useMemo(() => {
    
        const output: IGroupedSubformGridCell<TargetMeasurableLesion>[] = [
            {
                name: 'timepoint',
                content: (
                    <Input
                        component={OpenClinicaFormDisplay}
                        openClinicaUrl={openClinicaUrl}
                        eventCrfIdName="eventCrfId"
                        studyEventIdName="studyEventId"
                        btnMinWidth="140px"
                        eventCrfIdPrependParentName
                        eventCrfIdTableCell
                        studyEventIdPrependParentName
                        studyEventIdTableCell
                        textValueButton
                    />
                ),
                sortValue: (row) => row?.timepoint,
                width: 200
            },
            {
                name: 'tmlAssessmentDate',
                content: (
                    <Input
                        component={TextDisplay}
                    />
                ),
                sortValue: (row) => row?.earliestTmlAssessmentDate,
                width: 100,
                
            },
            {
                name: 'notDoneReason',
                content: (
                    <Box sx={{position: 'relative', mt: '0px', mb: '-13px'}}>
                        <div style={{position: 'absolute', width: '1350%'}}>
                            <Input
                                component={ValueWithReasonAndSpecificationDisplay}
                                reasonName="notDoneReasonSpecify"
                                reasonPrependParentName
                                reasonTableCell
                            />
                        </div>
                    </Box>
                ),
                sortValue: (row) => row?.notDoneReason,
                width: 100
                
            },
            presentTargetLesions.lesion1 ?
                {
                    name: 'lesion1Measurement',
                    content: (
                        <Input
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                        />
                    ),
                    sortValue: (row) => row?.lesion1Measurement,
                    width: 149
                } :
                null as any,
            presentTargetLesions.lesion2 ?
                {
                    name: 'lesion2Measurement',
                    content: (
                        <Input
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                        />
                    ),
                    sortValue: (row) => row?.lesion2Measurement,
                    width: 149
                } :
                null as any,
            presentTargetLesions.lesion3 ?
                {
                    name: 'lesion3Measurement',
                    content: (
                        <Input
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                        />
                    ),
                    sortValue: (row) => row?.lesion3Measurement,
                    width: 149
                } :
                null as any,
            presentTargetLesions.lesion4 ?
                {
                    name: 'lesion4Measurement',
                    content: (
                        <Input
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                        />
                    ),
                    sortValue: (row) => row?.lesion4Measurement,
                    width: 149
                } :
                null as any,
            presentTargetLesions.lesion5 ?
                {
                    name: 'lesion5Measurement',
                    content: (
                        <Input
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                        />
                    ),
                    sortValue: (row) => row?.lesion5Measurement,
                    width: 149
                } :
                null as any,
            {
                name: 'sumOfAllTargetLesions',
                content: (
                    <Input
                        component={NumericDisplay}
                        suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                    />
                ),
                sortValue: (row) => row?.sumOfAllTargetLesions
            },
            {
                name: 'targetLesionResponse',
                content: (
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasTargetLesionResponse}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Input
                            component={IfElseDisplay}
                            ifName="targetLesionResponse"
                            elseName="targetLesionResponse"
                            elseAccessor={(value: string) => "Missing"}
                            condition={(value: string) => (value?.length ?? 0) > 0}
                            ifPrependParentName={true}
                            ifTableCell={true}
                        />
                    </Condition>
                ),
                sortValue: (row) => row?.targetLesionResponse
            }
        ];

        return output.filter(x => x !== null);
    }, [presentTargetLesions]);









    const {state: { value: nonMeasurableLesions }} = useScopedField<NonMeasurableLesion[]>('nonMeasurableLesions', lesionsub);

    const presentNonLesions = useMemo(() => {
        const output = {
            lesion1Non: !!nonMeasurableLesions?.find(x => x.lesion1Status !== null && x.lesion1Status !== undefined),
            lesion2Non: !!nonMeasurableLesions?.find(x => x.lesion2Status !== null && x.lesion2Status !== undefined),
            lesion3Non: !!nonMeasurableLesions?.find(x => x.lesion3Status !== null && x.lesion3Status !== undefined),
            lesion4Non: !!nonMeasurableLesions?.find(x => x.lesion4Status !== null && x.lesion4Status !== undefined),
            lesion5Non: !!nonMeasurableLesions?.find(x => x.lesion5Status !== null && x.lesion5Status !== undefined),
            count: 0,
            columnWidth: 0
        };

        if (output.lesion1Non) {
            output.count++;
        }

        if (output.lesion2Non) {
            output.count++;
        }

        if (output.lesion3Non) {
            output.count++;
        }

        if (output.lesion4Non) {
            output.count++;
        }

        if (output.lesion5Non) {
            output.count++;
        }

        output.columnWidth = output.count === 0 ? 0 : (58 / output.count);

        return output
    }, [nonMeasurableLesions]);





    const nonMeasurableLesionColumns = useMemo(() => {
    
        const output: IGroupedSubformGridCell<NonMeasurableLesion>[] = [
            {
                name: 'timepoint',
                content: (
                    <Input
                        component={OpenClinicaFormDisplay}
                        openClinicaUrl={openClinicaUrl}
                        eventCrfIdName="eventCrfId"
                        studyEventIdName="studyEventId"
                        btnMinWidth="140px"
                        eventCrfIdPrependParentName
                        eventCrfIdTableCell
                        studyEventIdPrependParentName
                        studyEventIdTableCell
                        textValueButton
                    />
                ),
                sortValue: (row) => row?.timepoint,
                width: 200
            },
            {
                name: 'nmlAssessmentDate',
                content: (
                    <Input
                        component={TextDisplay}
                    />
                ),
                sortValue: (row) => row?.earliestNmlAssessmentDate,
                width: 100,
                
            },
            {
                name: 'notDoneReason',
                content: (
                    <Box sx={{position: 'relative', mt: '0px', mb: '-13px'}}>
                        <div style={{position: 'absolute', width: '1350%'}}>
                            <Input
                                component={ValueWithReasonAndSpecificationDisplay}
                                reasonName="notDoneReasonSpecify"
                                reasonPrependParentName
                                reasonTableCell
                            />
                        </div>
                    </Box>
                ),
                sortValue: (row) => row?.notDoneReason,
                width: 100
                
            },
            presentNonLesions.lesion1Non ?
                {
                    name: 'lesion1Status',
                    content: (
                        <Input component={TextDisplay} />
                    ),
                    sortValue: (row) => row?.lesion1Status,
                    width: 149
                } :
                null as any,
            presentNonLesions.lesion2Non ?
                {
                    name: 'lesion2Status',
                    content: (
                        <Input component={TextDisplay} />
                    ),
                    sortValue: (row) => row?.lesion2Status,
                    width: 149
                } :
                null as any,
            presentNonLesions.lesion3Non ?
                {
                    name: 'lesion3Status',
                    content: (
                        <Input component={TextDisplay} />
                    ),
                    sortValue: (row) => row?.lesion3Status,
                    width: 149
                } :
                null as any,
            presentNonLesions.lesion4Non ?
                {
                    name: 'lesion4Status',
                    content: (
                        <Input component={TextDisplay} />
                    ),
                    sortValue: (row) => row?.lesion4Status,
                    width: 149
                } :
                null as any,
            presentNonLesions.lesion5Non ?
                {
                    name: 'lesion5Status',
                    content: (
                        <Input component={TextDisplay} />
                    ),
                    sortValue: (row) => row?.lesion5Status,
                    width: 149
                } :
                null as any,
            {
                name: 'nonTargetLesionResponse',
                content: (
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNonTargetLesionResponse}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Input
                            component={IfElseDisplay}
                            ifName="nonTargetLesionResponse"
                            elseName="nonTargetLesionResponse"
                            elseAccessor={(value: string) => "Missing"}
                            condition={(value: string) => (value?.length ?? 0) > 0}
                            ifPrependParentName={true}
                            ifTableCell={true}
                        />
                    </Condition>
                ),
                sortValue: (row) => row?.nonTargetLesionResponse,
                
            }
        ];

        return output.filter(x => x !== null);
    }, [presentNonLesions]);








    return (
        <>
            <Box className={classes.titleContainer}>
                <Typography variant="h1">
                    <ContextFormTitle />
                </Typography>
                <FormDates />
            </Box>
            <GroupedFields>
                <Typography variant="h2" className={classes.subtitle}>
                    Target Measurable Lesions
                </Typography>
                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasTargetMeasurableLesions}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <Box
                        sx={{
                            py: 2
                        }}
                        // className={classes.table}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: 422
                                        }}
                                    >
                                    </TableCell>
                                    {
                                        presentTargetLesions.lesion1 && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 1
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion2 && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 2
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion3 && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 3
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion4 && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 4
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion5 && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 5
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                        <Typography variant='h5'>
                                            Site
                                        </Typography>
                                    </TableCell>
                                    {
                                        presentTargetLesions.lesion1 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion1Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="targetMeasurableLesion1SiteSpec"
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion2 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion2Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="targetMeasurableLesion2SiteSpec"
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion3 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion3Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="targetMeasurableLesion3SiteSpec"
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion4 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion4Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="targetMeasurableLesion4SiteSpec"
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion5 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion5Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="targetMeasurableLesion5SiteSpec"
                                                />
                                            </TableCell>
                                        )
                                    }
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                        <Typography variant='h5'>
                                            Location in organ
                                        </Typography>
                                    </TableCell>
                                    {
                                        presentTargetLesions.lesion1 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion1OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion2 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion2OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion3 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion3OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion4 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion4OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>
                                        )
                                    }
                                    {
                                        presentTargetLesions.lesion5 && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="targetMeasurableLesion5OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>
                                        )
                                    }
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <GroupedSubformGrid
                            
                            type={TargetMeasurableLesion}
                            name="targetMeasurableLesions"
                            columns={targetMeasurableLesionColumns}
                            disabled
                        />
                    </Box>
                </Condition>
                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasNoTargetMeasurableLesions}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Target Measurable Lesions"
                            description="There is no target measurable lesion associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
            </GroupedFields>



            <GroupedFields>
                <Typography variant="h2" className={classes.subtitle}>
                    Non-Measurable Lesions
                </Typography>
                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasNonMeasurableLesions}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <Box
                        sx={{
                            py: 2
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: 422
                                        }}
                                    >
                                    </TableCell>

                                    {
                                        presentNonLesions.lesion1Non && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 1
                                                </Typography>
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion2Non && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 2
                                                </Typography>
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion3Non && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 3
                                                </Typography>
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion4Non && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 4
                                                </Typography>
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion5Non && (
                                            <TableCell
                                                sx={{
                                                    width: 149
                                                }}
                                            >
                                                <Typography variant='h3'>
                                                    LESION 5
                                                </Typography>
                                            </TableCell>

                                        )
                                    }
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                        <Typography variant='h5'>
                                            Site
                                        </Typography>
                                    </TableCell>



                                    {
                                        presentNonLesions.lesion1Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion1Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="nonMeasurableLesion1SiteSpec"
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion2Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion2Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="nonMeasurableLesion2SiteSpec"
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion3Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion3Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="nonMeasurableLesion3SiteSpec"
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion4Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion4Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="nonMeasurableLesion4SiteSpec"
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion5Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion5Site"
                                                    component={ValueWithSpecificationDisplay}
                                                    specificationName="nonMeasurableLesion5SiteSpec"
                                                />
                                            </TableCell>

                                        )
                                    }
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                        <Typography variant='h5'>
                                            Location in organ
                                        </Typography>
                                    </TableCell>


                                    
                                    {
                                        presentNonLesions.lesion1Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion1OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion2Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion2OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion3Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion3OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion4Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion4OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>

                                        )
                                    }
                                    {
                                        presentNonLesions.lesion5Non && (
                                            <TableCell
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                <InputOnlyField
                                                    name="nonMeasurableLesion5OrganLocation"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>

                                        )
                                    }
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                    <GroupedSubformGrid
                        type={NonMeasurableLesion}
                        name="nonMeasurableLesions"
                        columns={nonMeasurableLesionColumns}
                        disabled
                    />
                </Condition>
                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasNoNonMeasurableLesions}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Non-Measurable Lesions"
                            description="There is no non-measurable lesion associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
            </GroupedFields>
            <GroupedFields>
                <Typography variant="h2" className={classes.subtitle}>
                    New Lesions
                </Typography>

                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasNewLesions}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedSubformGrid
                        type={NewLesion}
                        name="newLesions"
                        columns={newLesionColumns}
                        disabled
                    />
                </Condition>
                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasNoNewLesions}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No New Lesions"
                            description="There is no new lesion associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
            </GroupedFields>
            <GroupedFields>
                <Typography variant="h2" className={classes.subtitle}>
                    Overall Response
                </Typography>

                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasOverallResponse}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedSubformGrid
                        type={OverallResponse}
                        name="overallResponses"
                        columns={overallResponseColumns}
                        disabled
                    />
                </Condition>
                <Condition
                    type={TumourAssessmentsDto}
                    condition={hasNoOverallResponse}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Overall Responses"
                            description="There is no overall response associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
            </GroupedFields>

            <Annotations />
            <SourceDocuments />
            <ContextFormQueryGrid name="queries" />
        </>
    )
}

export default TumourAssessments;
