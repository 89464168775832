import { Box, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { Biochemistry, Coagulation, Ecg, Haematology, LiverEnzymePanel, LvefAssessment, OrganFunction as OrganFunctionDto, ThyroidFunction } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import BiochemistryDisplay from './input/display/BiochemistryDisplay';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IOrganFunctionProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const haematologyColumns: IGroupedSubformGridCell<Haematology>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'haemoglobin',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="haemoglobinNotDone"
                llnName="haemoglobinLln"
                ulnName="haemoglobinUln"
                unitName="haemoglobinUnit"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'haemoglobinNotDone'
                },
                {
                    name: 'haemoglobinLln'
                },
                {
                    name: 'haemoglobinUln'
                },
                {
                    name: 'haemoglobinUnit'
                }
            ]
        }
    },
    {
        name: 'neutrophilCount',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="neutrophilCountNotDone"
                llnName="neutrophilCountLln"
                ulnName="neutrophilCountUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> x10<sup>9</sup>/L</Typography>}
                prependParentName
                tableCell
                eligibilityLlnName="neutrophilCountEligibilityLln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'neutrophilCountNotDone'
                },
                {
                    name: 'neutrophilCountLln'
                },
                {
                    name: 'neutrophilCountUln'
                }
            ]
        }
    },
    {
        name: 'platelets',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="plateletsNotDone"
                llnName="plateletsLln"
                ulnName="plateletsUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> x10<sup>9</sup>/L</Typography>}
                prependParentName
                tableCell
                eligibilityLlnName="plateletsEligibilityLln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'plateletsNotDone'
                },
                {
                    name: 'plateletsLln'
                },
                {
                    name: 'plateletsUln'
                }
            ]
        }
    },
    {
        name: 'lymphocyteCount',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="lymphocyteCountNotDone"
                llnName="lymphocyteCountLln"
                ulnName="lymphocyteCountUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> x10<sup>9</sup>/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lymphocyteCountNotDone'
                },
                {
                    name: 'lymphocyteCountLln'
                },
                {
                    name: 'lymphocyteCountUln'
                }
            ]
        }
    }
];

const biochemistry1Columns: IGroupedSubformGridCell<Biochemistry>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'urea',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="ureaNotDone"
                llnName="ureaLln"
                ulnName="ureaUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ureaNotDone'
                },
                {
                    name: 'ureaLln'
                },
                {
                    name: 'ureaUln'
                }
            ]
        }
    },
    {
        name: 'creatinine',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="creatinineNotDone"
                llnName="creatinineLln"
                ulnName="creatinineUln"
                unitName="creatinineUnit"
                prependParentName
                tableCell
                eligibilityUlnName="creatinineEligibilityUln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'creatinineNotDone'
                },
                {
                    name: 'creatinineLln'
                },
                {
                    name: 'creatinineUln'
                },
                {
                    name: 'creatinineUnit'
                }
            ]
        }
    },
    {
        name: 'creatinineClearance',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> 	mL/min</Typography>}
                llnName=""
                ulnName=""
                eligibilityLlnName="creatinineClearanceEligibilityLln"
                prependParentName
                tableCell
            />
        )
    },
    {
        name: 'sodium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="sodiumNotDone"
                llnName="sodiumLln"
                ulnName="sodiumUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'sodiumNotDone'
                },
                {
                    name: 'sodiumLln'
                },
                {
                    name: 'sodiumUln'
                }
            ]
        }
    },
    {
        name: 'potassium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="potassiumNotDone"
                llnName="potassiumLln"
                ulnName="potassiumUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'potassiumNotDone'
                },
                {
                    name: 'potassiumLln'
                },
                {
                    name: 'potassiumUln'
                }
            ]
        }
    },
    {
        name: 'chloride',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="chlorideNotDone"
                llnName="chlorideLln"
                ulnName="chlorideUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'chlorideNotDone'
                },
                {
                    name: 'chlorideLln'
                },
                {
                    name: 'chlorideUln'
                }
            ]
        }
    },
    {
        name: 'bicarbonate',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="bicarbonateNotDone"
                llnName="bicarbonateLln"
                ulnName="bicarbonateUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'bicarbonateNotDone'
                },
                {
                    name: 'bicarbonateLln'
                },
                {
                    name: 'bicarbonateUln'
                }
            ]
        }
    }
];

const biochemistry2Columns: IGroupedSubformGridCell<Biochemistry>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'correctedCalcium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="correctedCalciumNotDone"
                llnName="correctedCalciumLln"
                ulnName="correctedCalciumUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'correctedCalciumNotDone'
                },
                {
                    name: 'correctedCalciumLln'
                },
                {
                    name: 'correctedCalciumUln'
                }
            ]
        }
    },
    {
        name: 'magnesium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="magnesiumNotDone"
                llnName="magnesiumLln"
                ulnName="magnesiumUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'magnesiumNotDone'
                },
                {
                    name: 'magnesiumLln'
                },
                {
                    name: 'magnesiumUln'
                }
            ]
        }
    },
    {
        name: 'ldh',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="ldhNotDone"
                llnName="ldhLln"
                ulnName="ldhUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ldhNotDone'
                },
                {
                    name: 'ldhLln'
                },
                {
                    name: 'ldhUln'
                }
            ]
        }
    },
    {
        name: 'bloodGlucose',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="bloodGlucoseNotDone"
                llnName="bloodGlucoseLln"
                ulnName="bloodGlucoseUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'bloodGlucoseNotDone'
                },
                {
                    name: 'bloodGlucoseLln'
                },
                {
                    name: 'bloodGlucoseUln'
                }
            ]
        }
    },
    {
        name: 'totalProtein',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="totalProteinNotDone"
                llnName="totalProteinLln"
                ulnName="totalProteinUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> g/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalProteinNotDone'
                },
                {
                    name: 'totalProteinLln'
                },
                {
                    name: 'totalProteinUln'
                }
            ]
        }
    },
    {
        name: 'amylase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="amylaseNotDone"
                llnName="amylaseLln"
                ulnName="amylaseUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'amylaseNotDone'
                },
                {
                    name: 'amylaseLln'
                },
                {
                    name: 'amylaseUln'
                }
            ]
        }
    },
    {
        name: 'lipase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="lipaseNotDone"
                llnName="lipaseLln"
                ulnName="lipaseUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lipaseNotDone'
                },
                {
                    name: 'lipaseLln'
                },
                {
                    name: 'lipaseUln'
                }
            ]
        }
    },
    {
        name: 'fastingSerumCortisol',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="fastingSerumCortisolNotDone"
                llnName="fastingSerumCortisolLln"
                ulnName="fastingSerumCortisolUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> nmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'fastingSerumCortisolNotDone'
                },
                {
                    name: 'fastingSerumCortisolLln'
                },
                {
                    name: 'fastingSerumCortisolUln'
                }
            ]
        }
    }
];

const liverEnzymePanelColumns: IGroupedSubformGridCell<LiverEnzymePanel>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'totalBilirubin',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="totalBilirubinNotDone"
                llnName="totalBilirubinLln"
                ulnName="totalBilirubinUln"
                unitName="totalBilirubinUnit"
                prependParentName
                tableCell
                eligibilityUlnName="totalBilirubinEligibilityUln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalBilirubinNotDone'
                },
                {
                    name: 'totalBilirubinLln'
                },
                {
                    name: 'totalBilirubinUln'
                },
                {
                    name: 'totalBilirubinUnit'
                }
            ]
        }
    },
    {
        name: 'alp',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="alpNotDone"
                llnName="alpLln"
                ulnName="alpUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'alpNotDone'
                },
                {
                    name: 'alpLln'
                },
                {
                    name: 'alpUln'
                }
            ]
        }
    },
    {
        name: 'alt',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="altNotDone"
                llnName="altLln"
                ulnName="altUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
                eligibilityUlnName="altEligibilityUln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'altNotDone'
                },
                {
                    name: 'altLln'
                },
                {
                    name: 'altUln'
                }
            ]
        }
    },
    {
        name: 'ast',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="astNotDone"
                llnName="astLln"
                ulnName="astUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'astNotDone'
                },
                {
                    name: 'astLln'
                },
                {
                    name: 'astUln'
                }
            ]
        }
    },
    {
        name: 'ggt',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="ggtNotDone"
                llnName="ggtLln"
                ulnName="ggtUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ggtNotDone'
                },
                {
                    name: 'ggtLln'
                },
                {
                    name: 'ggtUln'
                }
            ]
        }
    },
    {
        name: 'totalAlbumin',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="totalAlbuminNotDone"
                llnName="totalAlbuminLln"
                ulnName="totalAlbuminUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> g/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalAlbuminNotDone'
                },
                {
                    name: 'totalAlbuminLln'
                },
                {
                    name: 'totalAlbuminUln'
                }
            ]
        }
    }
];

const thyroidFunctionColumns: IGroupedSubformGridCell<ThyroidFunction>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'tsh',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="tshNotDone"
                llnName="tshLln"
                ulnName="tshUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mIU/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'tshNotDone'
                },
                {
                    name: 'tshLln'
                },
                {
                    name: 'tshUln'
                }
            ]
        }
    },
    {
        name: 't3',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="t3NotDone"
                llnName="t3Lln"
                ulnName="t3Uln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> pmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 't3NotDone'
                },
                {
                    name: 't3Lln'
                },
                {
                    name: 't3Uln'
                }
            ]
        }
    },
    {
        name: 't4',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="t4NotDone"
                llnName="t4Lln"
                ulnName="t4Uln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> pmol/L</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 't4NotDone'
                },
                {
                    name: 't4Lln'
                },
                {
                    name: 't4Uln'
                }
            ]
        }
    }
];

const coagulationColumns: IGroupedSubformGridCell<Coagulation>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'pt',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="ptNotDone"
                llnName="ptLln"
                ulnName="ptUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> seconds</Typography>}
                prependParentName
                tableCell
                eligibilityUlnName="ptEligibilityUln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ptNotDone'
                },
                {
                    name: 'ptLln'
                },
                {
                    name: 'ptUln'
                }
            ]
        }
    },
    {
        name: 'appt',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="apptNotDone"
                llnName="apptLln"
                ulnName="apptUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> seconds</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'apptNotDone'
                },
                {
                    name: 'apptLln'
                },
                {
                    name: 'apptUln'
                }
            ]
        }
    },
    {
        name: 'inr',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                notDoneName="inrNotDone"
                llnName="inrLln"
                ulnName="inrUln"
                suffix={<Typography color="textSecondary" variant="caption" component="span"> ratio</Typography>}
                prependParentName
                tableCell
                eligibilityUlnName="inrEligibilityUln"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'inrNotDone'
                },
                {
                    name: 'inrLln'
                },
                {
                    name: 'inrUln'
                }
            ]
        }
    }
];

const lvefAssessmentColumns: IGroupedSubformGridCell<LvefAssessment>[] = [
    {
        name: 'eventName',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'type',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'lvef',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="lvefLln"
                ulnName=""
                suffix={<Typography color="textSecondary" variant="caption" component="span"> %</Typography>}
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lvefLln'
                }
            ]
        }
    }
];

const ecgColumns: IGroupedSubformGridCell<Ecg>[] = [
    {
        name: 'eventName',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        width: 350,
    },
    {
        name: 'qtcf',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> ms</Typography>}
            />
        )
    }
];

const hasHaematology: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.haematology && value?.haematology?.length > 0;
const hasNoHaematology: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.haematology || value?.haematology?.length === 0;

const hasBiohemistry: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.biochemistry && value?.biochemistry?.length > 0;
const hasNoBiohemistry: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.biochemistry || value?.biochemistry?.length === 0;

const hasLiverEnzymePanel: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.liverEnzymePanel && value?.liverEnzymePanel?.length > 0;
const hasNoLiverEnzymePanel: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.liverEnzymePanel || value?.liverEnzymePanel?.length === 0;

const hasThyroidFunction: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.thyroidFunction && value?.thyroidFunction?.length > 0;
const hasNoThyroidFunction: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.thyroidFunction || value?.thyroidFunction?.length === 0;

const hasCoagulation: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.coagulation && value?.coagulation?.length > 0;
const hasNoCoagulation: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.coagulation || value?.coagulation?.length === 0;

const hasLvefAssessment: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.lvefAssessment && value?.lvefAssessment?.length > 0;
const hasNoLvefAssessment: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.lvefAssessment || value?.lvefAssessment?.length === 0;

const hasEcg: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.ecg && value?.ecg?.length > 0;
const hasNoEcg: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !value?.ecg || value?.ecg?.length === 0;

const OrganFunction: FunctionComponent<IOrganFunctionProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    return (
        <div>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box>
                    <Box className={classes.titleContainer}>
                        <Typography variant="h1">
                            <ContextFormTitle />
                        </Typography>
                        <FormDates />
                    </Box>
                    <Typography sx={{ padding: theme.spacing(0, 2, 2), color: theme.palette.primary.main }}>
                        Note: Results that do not meet the protocol defined eligibility criteria are asterisked (*) for the screening event.
                    </Typography>
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Haematology
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasHaematology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Haematology}
                            name="haematology"
                            columns={haematologyColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoHaematology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Haematology Data"
                                description="There is no haematology information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Biochemistry
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasBiohemistry}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Biochemistry}
                            name="biochemistry"
                            columns={biochemistry1Columns}
                            disabled
                        />
                        <GroupedSubformGrid
                            type={Biochemistry}
                            name="biochemistry"
                            columns={biochemistry2Columns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoBiohemistry}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Biochemistry Data"
                                description="There is no biochemistry information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Liver Enzyme Panel
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasLiverEnzymePanel}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="gilbertSyndrome"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedSubformGrid
                            type={LiverEnzymePanel}
                            name="liverEnzymePanel"
                            columns={liverEnzymePanelColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoLiverEnzymePanel}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Liver Enzyme Panel Data"
                                description="There is no liver enzyme panel information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                       Thyroid Function
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasThyroidFunction}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ThyroidFunction}
                            name="thyroidFunction"
                            columns={thyroidFunctionColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoThyroidFunction}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Thyroid Function Data"
                                description="There is no thyroid function information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Coagulation
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasCoagulation}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Coagulation}
                            name="coagulation"
                            columns={coagulationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoCoagulation}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Coagulation Data"
                                description="There is no coagulation information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        LVEF Assessment
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasLvefAssessment}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={LvefAssessment}
                            name="lvefAssessment"
                            columns={lvefAssessmentColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoLvefAssessment}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No LVEF Assessment Data"
                                description="There is no LVEF assessment information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                       ECG
                    </Typography>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasEcg}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Ecg}
                            name="ecg"
                            columns={ecgColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasNoEcg}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No ECG Data"
                                description="There is no ECG information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </div>
    );
};

export default OrganFunction;
