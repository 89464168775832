import { Box, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import {
    ContextFormTitle,
    ContextPatientInformation,
    ContextMedicalReviewFormBreadcrumbs, 
    ContextMedicalReviewForm,
    ContextFormQueryGrid,
    GroupedField
} from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import BiochemistryDisplay from './input/display/BiochemistryDisplay';
import FormDates from '../../components/form/FormDates';

interface IOrganFunctionEligibilityProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2),
        color: theme.palette.secondary.main
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const OrganFunctionEligibility: FunctionComponent<IOrganFunctionEligibilityProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2),
            color: theme.palette.secondary.main
        },
        titleContainer: {
            padding: theme.spacing(2, 2, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }, 
        subtitle: {
            padding: theme.spacing(1, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        },
    }), [theme])

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        PATIENT FUNCTION
                    </Typography>
                    <Typography sx={{ padding: theme.spacing(0,2,2) }}>
                        <i>Note: results for screening laboratory tests are included in the on-treatment laboratory tables.</i>
                    </Typography>
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline ECOG
                    </Typography>
                    <GroupedField
                        name="ecog"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName=""
                        ulnName="ecogUln"
                        unitName=""
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        HEPATITIS SCREENING
                    </Typography>
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline HEPATITIS/HIV SCREENING
                    </Typography>
                    <GroupedField
                        name="hbsag"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="hcvAntibody"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="hivAntigen"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        CARDIAC FUNCTION
                    </Typography>
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline ECG
                    </Typography>
                    <GroupedField
                        name="ecg"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName=""
                        ulnName="ecgUln"
                        unitName=""
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> ms</Typography> 
                        }
                    />
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline LVEF
                    </Typography>
                    <GroupedField
                        name="echoMuga"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="lvef"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName="lvefLln"
                        ulnName=""
                        unitName=""
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> %</Typography>
                        }
                        eligibilityLlnName="lvefEligibilityLln"
                    />
                    <GroupedField
                        name="lvefLln"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> %</Typography>
                        }
                    />
                    <GroupedField
                        name="lvefAssessmentDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default OrganFunctionEligibility;
