import { Box, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { MedicalHistoryAndPriorTreatment as MedicalHistoryAndPriorTreatmentDto, ProtocolDeviation, Comment, MedicalHistory, Chemotherapy, ConcomitantMedication, AntiHer2Therapy, EndocrineTherapy, Radiation, OtherTreatment } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';

interface IMedicalHistoryAndPriorTreatmentProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2),
        color: theme.palette.secondary.main
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const medicalHistoryColumns: IGroupedSubformGridCell<MedicalHistory>[] = [
    {
        name: 'medicalCondition',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.medicalCondition
    },
    {
        name: 'current',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.current
    },
    {
        name: 'dateOfDiagnosis',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfDiagnosis ? DateTime.fromFormat(row.dateOfDiagnosis.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'treatmentOngoing',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.treatmentOngoing
    }
];

const conMedsColumns: IGroupedSubformGridCell<ConcomitantMedication>[] = [
    {
        name: 'genericName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.genericName
    },
    {
        name: 'indication',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="indicationSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'startDate',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.startDate ? DateTime.fromFormat(row.startDate.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    }
];

const pbctColumns: IGroupedSubformGridCell<Chemotherapy|AntiHer2Therapy|EndocrineTherapy>[] = [
    {
        name: 'type',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="typeSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.type
    },
    {
        name: 'use',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.use
    },
    {
        name: 'current',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.current
    },
    {
        name: 'dateOfCommencement',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfCommencement ? DateTime.fromFormat(row.dateOfCommencement.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'dateOfCompletion',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfCompletion ? DateTime.fromFormat(row.dateOfCompletion.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    }
];

const radiationColumns: IGroupedSubformGridCell<Radiation>[] = [
    {
        name: 'site',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="siteSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'use',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.use
    },
    {
        name: 'current',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.current
    },
    {
        name: 'dateOfCommencement',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfCommencement ? DateTime.fromFormat(row.dateOfCommencement.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'dateOfCompletion',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfCompletion ? DateTime.fromFormat(row.dateOfCompletion.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    }
];

const otherTreatmentColumns: IGroupedSubformGridCell<OtherTreatment>[] = [
    {
        name: 'specify',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.specify
    },
    {
        name: 'use',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.use
    },
    {
        name: 'current',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.current
    },
    {
        name: 'dateOfCommencement',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfCommencement ? DateTime.fromFormat(row.dateOfCommencement.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'dateOfCompletion',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfCompletion ? DateTime.fromFormat(row.dateOfCompletion.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    }
];

const hasNoMedicalHistory: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.medicalHistory || value?.medicalHistory?.length === 0;;
const hasMedicalHistory: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.medicalHistory && value?.medicalHistory?.length > 0;

const hasNoConMeds: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.concomitantMedications || value?.concomitantMedications?.length === 0;
const hasConMeds: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.concomitantMedications && value?.concomitantMedications?.length > 0;

const hasNoChemotherapy: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.chemotherapy || value?.chemotherapy?.length === 0;
const hasChemotherapy: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.chemotherapy && value?.chemotherapy?.length > 0;

const hasNoAntiHer2Therapy: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.antiHer2Therapy || value?.antiHer2Therapy?.length === 0;
const hasAntiHer2Therapy: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.antiHer2Therapy && value?.antiHer2Therapy?.length > 0;

const hasNoEndocrineTherapy: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.endocrineTherapy || value?.endocrineTherapy?.length === 0;
const hasEndocrineTherapy: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.endocrineTherapy && value?.endocrineTherapy?.length > 0;

const hasNoRadiation: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.radiation || value?.radiation?.length === 0;
const hasRadiation: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.radiation && value?.radiation?.length > 0;

const hasNoOtherTreatment: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.otherTreatments || value?.otherTreatments?.length === 0;
const hasOtherTreatment: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.otherTreatments && value?.otherTreatments?.length > 0;


const conditionSubscription = { value: true };

const MedicalHistoryAndPriorTreatment: FunctionComponent<IMedicalHistoryAndPriorTreatmentProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2),
            color: theme.palette.secondary.main
        },
        titleContainer: {
            padding: theme.spacing(2, 2, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }, 
        subtitle: {
            padding: theme.spacing(2, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        }
    }), [theme])

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        MEDICAL HISTORY
                    </Typography>

                    <GroupedField
                        name="dateOfFirstDiagnosis"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <Typography variant="h2" sx={styles.subtitle}>
                        Relevant Medical History
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasMedicalHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={MedicalHistory}
                            name="medicalHistory"
                            columns={medicalHistoryColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoMedicalHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Medical History"
                                description="There is no medical history associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.subtitle}>
                        Concomitant Medications
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ConcomitantMedication}
                            name="concomitantMedications"
                            columns={conMedsColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Concomitant Medications"
                                description="There is no concomitant medication associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        PREVIOUS BREAST CANCER TREATMENT
                    </Typography>

                    <Typography variant="h2" sx={styles.subtitle}>
                        Chemotherapy
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasChemotherapy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Chemotherapy}
                            name="chemotherapy"
                            columns={pbctColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoChemotherapy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Chemotherapy"
                                description="There is no chemotherapy associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.subtitle}>
                        Anti-HER2 Therapy
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasAntiHer2Therapy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={AntiHer2Therapy}
                            name="antiHer2Therapy"
                            columns={pbctColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoAntiHer2Therapy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Anti-HER2 Therapy"
                                description="There is no anti-HER2 therapy associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.subtitle}>
                        Endocrine Therapy
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasEndocrineTherapy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={EndocrineTherapy}
                            name="endocrineTherapy"
                            columns={pbctColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoEndocrineTherapy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Endocrine Therapy"
                                description="There is no endocrine therapy associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Radiation
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasRadiation}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Radiation}
                            name="radiation"
                            columns={radiationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoRadiation}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Radiations"
                                description="There is no radiation associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Other Treatments
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasOtherTreatment}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={OtherTreatment}
                            name="otherTreatments"
                            columns={otherTreatmentColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoOtherTreatment}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Other Treatments"
                                description="There is no other treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default MedicalHistoryAndPriorTreatment;
