import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, OpenClinicaFormDisplay, OpmsSaeDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, IValidationError } from '@ngt/forms';
import { AdverseEventsAndSaes as AdverseEventsAndSaesDto, AdverseEvent, SeriousAdverseEvent, DurvalumabTreatment } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, useScopedField } from '@ngt/forms-core';
import { BasicAlert, useFullWidthLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface IAdverseEventsAndSaesProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const suspectDrugAccessor = () => "None";

const adverseEventColumns: IGroupedSubformGridCell<AdverseEvent>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'systemsOrganClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.systemsOrganClass
    },
    {
        name: 'aeTerm',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="aeTermSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'aeTermSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.aeTerm ? `${row?.aeTerm} - ${row?.aeTermSpecify}` : undefined
    },
    {
        name: 'grade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.grade
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.outcome
    },
    {
        name: 'resolutionDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.resolutionDate
    },
    {
        name: 'aeRelationship',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.aeRelationship
    },
    {
        name: 'actionTaken',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTaken
    },
    {
        name: 'qualifyAsAnAesi',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.qualifyAsAnAesi
    },
    {
        name: 'meetSaeCriteria',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.meetSaeCriteria
    }
];

const seriousAdverseEventColumns: IGroupedSubformGridCell<SeriousAdverseEvent>[] = [
    {
        name: 'saeNumber',
        content: (
            <Input
                component={OpmsSaeDisplay}
                opmsUrl={opmsUrl}
                saeIdName="opmsSaeId"
                btnMinWidth="50px"
                saeIdPrependParentName
                saeIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.saeNumber
    },
    {
        name: 'term',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.term
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.outcome
    },
    {
        name: 'outcomeDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.outcomeDate
    },
    {
        name: 'suspectDrugOrCause',
        content: (
            <Input
                component={IfElseDisplay}
                ifPrependParentName
                ifTableCell
                ifName="suspectDrugOrCause"
                elsePrependParentName
                elseTableCell
                elseAccessor={suspectDrugAccessor}
                elseName="suspectDrugOrCause"
            />

        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'suspectDrugOrCause'
                }
            ]
        },
        sortValue: (row) => row?.suspectDrugOrCause ?? 'None'
    },
    {
        name: 'expectedness',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.expectedness
    },
    {
        name: 'comments',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.comments
    }
];

const hasNoCycleDates: ICondition<AdverseEventsAndSaesDto> = ({ formState: { value } = {} }) => !value?.cycleDates || value?.cycleDates?.length === 0;
const hasCycleDates: ICondition<AdverseEventsAndSaesDto> = ({ formState: { value } = {} }) => !!value?.cycleDates && value?.cycleDates?.length > 0;

const hasNoAes: ICondition<AdverseEventsAndSaesDto> = ({ formState: { value } = {} }) => !value?.adverseEvents || value?.adverseEvents?.length === 0;
const hasAes: ICondition<AdverseEventsAndSaesDto> = ({ formState: { value } = {} }) => !!value?.adverseEvents && value?.adverseEvents?.length > 0;

const hasNoSaes: ICondition<AdverseEventsAndSaesDto> = ({ formState: { value } = {} }) => !value?.seriousAdverseEvents || value?.seriousAdverseEvents?.length === 0;
const hasSaes: ICondition<AdverseEventsAndSaesDto> = ({ formState: { value } = {} }) => !!value?.seriousAdverseEvents && value?.seriousAdverseEvents?.length > 0;

const conditionSubscription = { value: true };

const CycleDates = () => {
    const { state: { value: cycleDates } } = useScopedField<Array<DurvalumabTreatment>, IValidationError>('cycleDates', conditionSubscription, false);

    const theme = useTheme();

    if (!cycleDates) {
        return <></>;
    }

    return (
        <Grid
            container
            spacing={1}
            sx={{
                width: '100% !important',
                padding: theme.spacing(2),

            }}
        >
            {
                cycleDates?.map((cycleDate, i) => {
                    return (
                        <Grid
                            item
                            sx={{
                                minWidth: '220px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid black',
                                    padding: theme.spacing(4, 2),
                                    width: '100%'
                                }}
                            >
                                <Typography style={{ marginRight: theme.spacing(1), fontWeight: 500 }}>
                                    {cycleDate.eventName}
                                </Typography>
                                <Typography>
                                    {
                                        cycleDate.administered === false ?
                                            'Not Administered'
                                            :
                                            cycleDate.date ? DateTime.fromISO(cycleDate.date).toFormat('dd/MM/yyyy') : ''
                                    }
                                </Typography>
                            </div>
                        </Grid>
                    );
                })
            }
        </Grid>
    );

}

const AdverseEventsAndSaes: FunctionComponent<IAdverseEventsAndSaesProps> = () => {
    const { classes } = useStyles();

    useFullWidthLayout();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Cycle Dates
                    </Typography>

                    <Typography className={classes.subtitle}>
                        (dates of administration of durvalumab)
                    </Typography>

                    <Condition
                        type={AdverseEventsAndSaesDto}
                        condition={hasCycleDates}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <CycleDates />
                    </Condition>
                    <Condition
                        type={AdverseEventsAndSaesDto}
                        condition={hasNoCycleDates}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Cycle Dates"
                                description="There is no cycle date associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Adverse Events
                    </Typography>

                    <Condition
                        type={AdverseEventsAndSaesDto}
                        condition={hasAes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={AdverseEvent}
                                name="adverseEvents"
                                columns={adverseEventColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={AdverseEventsAndSaesDto}
                        condition={hasNoAes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Adverse Events"
                                description="There is no adverse event associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Serious Adverse Event Summary
                    </Typography>

                    <Typography className={classes.subtitle}>
                        Please click on the SAE number to review the full SAE details in the SAE review module (including treatments and procedures).
                    </Typography>

                    <Condition
                        type={AdverseEventsAndSaesDto}
                        condition={hasSaes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div style={{ fontSize: '0.875rem' }}>
                            <GroupedSubformGrid
                                type={SeriousAdverseEvent}
                                name="seriousAdverseEvents"
                                columns={seriousAdverseEventColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={AdverseEventsAndSaesDto}
                        condition={hasNoSaes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Serious Adverse Events"
                                description="There is no serious adverse event associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default AdverseEventsAndSaes;
