/* Options:
Date: 2024-03-08 10:06:17
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44343

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IUserGetSingle
{
    metadata: { [index: string]: Object; };
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum MedicalReviewStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
}

export class AdverseEvent implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public systemsOrganClass: string;
    public aeTermId?: number;
    public aeTerm: string;
    public aeTermSpecify: string;
    public grade?: number;
    public onset?: string;
    public outcome: string;
    public resolutionDate?: string;
    public aeRelationship: string;
    public actionTaken: string;
    public qualifyAsAnAesi: string;
    public meetSaeCriteria: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvent>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByMedicalReviewIdAndRepeat extends IGet
{
    medicalReviewId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class Annotation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Annotation>) { (Object as any).assign(this, init); }
}

export class AntiHer2Therapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: string;
    public typeSpec: string;
    public use: string;
    public current: string;
    public dateOfCommencement: string;
    public dateOfCompletion: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AntiHer2Therapy>) { (Object as any).assign(this, init); }
}

export class AromataseTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public name: string;
    public nameSpecify: string;
    public dailyDose?: number;
    public startDate?: string;
    public stopDate?: string;
    public reason: string;
    public reasonSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AromataseTreatment>) { (Object as any).assign(this, init); }
}

export class Biochemistry implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public urea?: number;
    public ureaNotDone?: boolean;
    public ureaLln?: number;
    public ureaUln?: number;
    public creatinine?: number;
    public creatinineNotDone?: boolean;
    public creatinineLln?: number;
    public creatinineUln?: number;
    public creatinineEligibilityUln?: number;
    public creatinineUnit: string;
    public creatinineClearance?: number;
    public creatinineClearanceEligibilityLln?: number;
    public sodium?: number;
    public sodiumNotDone?: boolean;
    public sodiumLln?: number;
    public sodiumUln?: number;
    public potassium?: number;
    public potassiumNotDone?: boolean;
    public potassiumLln?: number;
    public potassiumUln?: number;
    public chloride?: number;
    public chlorideNotDone?: boolean;
    public chlorideLln?: number;
    public chlorideUln?: number;
    public bicarbonate?: number;
    public bicarbonateNotDone?: boolean;
    public bicarbonateLln?: number;
    public bicarbonateUln?: number;
    public correctedCalcium?: number;
    public correctedCalciumNotDone?: boolean;
    public correctedCalciumLln?: number;
    public correctedCalciumUln?: number;
    public magnesium?: number;
    public magnesiumNotDone?: boolean;
    public magnesiumLln?: number;
    public magnesiumUln?: number;
    public ldh?: number;
    public ldhNotDone?: boolean;
    public ldhLln?: number;
    public ldhUln?: number;
    public bloodGlucose?: number;
    public bloodGlucoseNotDone?: boolean;
    public bloodGlucoseLln?: number;
    public bloodGlucoseUln?: number;
    public totalProtein?: number;
    public totalProteinNotDone?: boolean;
    public totalProteinLln?: number;
    public totalProteinUln?: number;
    public amylase?: number;
    public amylaseNotDone?: boolean;
    public amylaseLln?: number;
    public amylaseUln?: number;
    public lipase?: number;
    public lipaseNotDone?: boolean;
    public lipaseLln?: number;
    public lipaseUln?: number;
    public fastingSerumCortisol?: number;
    public fastingSerumCortisolNotDone?: boolean;
    public fastingSerumCortisolLln?: number;
    public fastingSerumCortisolUln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Biochemistry>) { (Object as any).assign(this, init); }
}

export class Chemotherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: string;
    public typeSpec: string;
    public use: string;
    public current: string;
    public dateOfCommencement: string;
    public dateOfCompletion: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Chemotherapy>) { (Object as any).assign(this, init); }
}

export class Coagulation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public pt?: number;
    public ptNotDone?: boolean;
    public ptLln?: number;
    public ptUln?: number;
    public ptEligibilityUln?: number;
    public ptUnit: string;
    public appt?: number;
    public apptNotDone?: boolean;
    public apptLln?: number;
    public apptUln?: number;
    public apptUnit: string;
    public inr?: number;
    public inrNotDone?: boolean;
    public inrLln?: number;
    public inrUln?: number;
    public inrEligibilityUln?: number;
    public inrUnit: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Coagulation>) { (Object as any).assign(this, init); }
}

export class Comment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public date?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public crfName: string;
    public commentText: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Comment>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public genericName: string;
    public indication: string;
    public indicationSpec: string;
    public startDate: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationBaseline implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public genericMedicationName: string;
    public indication: string;
    public indicationSpec: string;
    public started: string;
    public stopped: string;
    public ongoing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedicationBaseline>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationOnStudy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public genericMedicationName: string;
    public indication: string;
    public indicationSpec: string;
    public started?: string;
    public stopped?: string;
    public ongoing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedicationOnStudy>) { (Object as any).assign(this, init); }
}

export class CycleInfo implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public cycleName: string;
    public cycleDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CycleInfo>) { (Object as any).assign(this, init); }
}

export class DurvalumabTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public weight?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DurvalumabTreatment>) { (Object as any).assign(this, init); }
}

export class Ecg implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public qtcf?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Ecg>) { (Object as any).assign(this, init); }
}

export class EndocrineTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: string;
    public typeSpec: string;
    public use: string;
    public current: string;
    public dateOfCommencement: string;
    public dateOfCompletion: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<EndocrineTherapy>) { (Object as any).assign(this, init); }
}

export class GoserelinTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered?: boolean;
    public date?: string;
    public injectionWithin: string;
    public injectionWithinReason: string;
    public injectionWithinSpec: string;
    public lastAdministration: string;
    public lastAdministrationReason: string;
    public lastAdministrationSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GoserelinTreatment>) { (Object as any).assign(this, init); }
}

export class Haematology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public haemoglobin?: number;
    public haemoglobinNotDone?: boolean;
    public haemoglobinLln?: number;
    public haemoglobinUln?: number;
    public haemoglobinUnit: string;
    public neutrophilCount?: number;
    public neutrophilCountNotDone?: boolean;
    public neutrophilCountLln?: number;
    public neutrophilCountUln?: number;
    public neutrophilCountEligibilityLln?: number;
    public platelets?: number;
    public plateletsEligibilityLln?: number;
    public plateletsNotDone?: boolean;
    public plateletsLln?: number;
    public plateletsUln?: number;
    public lymphocyteCount?: number;
    public lymphocyteCountNotDone?: boolean;
    public lymphocyteCountLln?: number;
    public lymphocyteCountUln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Haematology>) { (Object as any).assign(this, init); }
}

export class LiverEnzymePanel implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public totalBilirubin?: number;
    public totalBilirubinNotDone?: boolean;
    public totalBilirubinLln?: number;
    public totalBilirubinUln?: number;
    public totalBilirubinEligibilityUln?: number;
    public totalBilirubinUnit: string;
    public alp?: number;
    public alpNotDone?: boolean;
    public alpLln?: number;
    public alpUln?: number;
    public alpUnit: string;
    public alt?: number;
    public altNotDone?: boolean;
    public altLln?: number;
    public altUln?: number;
    public altEligibilityUln?: number;
    public altUnit: string;
    public ast?: number;
    public astNotDone?: boolean;
    public astLln?: number;
    public astUln?: number;
    public astUnit: string;
    public ggt?: number;
    public ggtNotDone?: boolean;
    public ggtLln?: number;
    public ggtUln?: number;
    public ggtUnit: string;
    public totalAlbumin?: number;
    public totalAlbuminNotDone?: boolean;
    public totalAlbuminLln?: number;
    public totalAlbuminUln?: number;
    public totalAlbuminUnit: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LiverEnzymePanel>) { (Object as any).assign(this, init); }
}

export class LvefAssessment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public type: string;
    public lvef?: number;
    public lvefLln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LvefAssessment>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicalCondition: string;
    public current: string;
    public dateOfDiagnosis: string;
    public treatmentOngoing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class NewLesion implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public lesionNumber: string;
    public date?: string;
    public methodOfMeasurement: string;
    public methodOfMeasurementSpec: string;
    public site: string;
    public siteSpec: string;
    public locationWithinOrgan: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewLesion>) { (Object as any).assign(this, init); }
}

export class NonMeasurableLesion implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public notDoneReason: string;
    public lesion1Status: string;
    public lesion2Status: string;
    public lesion3Status: string;
    public lesion4Status: string;
    public lesion5Status: string;
    public nonTargetLesionResponse: string;
    public nmlAssessmentDate: string;
    public earliestNmlAssessmentDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NonMeasurableLesion>) { (Object as any).assign(this, init); }
}

export class OtherTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public specify: string;
    public use: string;
    public current: string;
    public dateOfCommencement: string;
    public dateOfCompletion: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OtherTreatment>) { (Object as any).assign(this, init); }
}

export class OverallResponse implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public response: string;
    public assessmentDate: string;
    public earliestAssessmentDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OverallResponse>) { (Object as any).assign(this, init); }
}

export class ProtocolDeviation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProtocolDeviation>) { (Object as any).assign(this, init); }
}

export class Radiation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public site: string;
    public siteSpec: string;
    public use: string;
    public current: string;
    public dateOfCommencement: string;
    public dateOfCompletion: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Radiation>) { (Object as any).assign(this, init); }
}

export class SeriousAdverseEvent implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public opmsSaeId?: number;
    public saeNumber?: number;
    public term: string;
    public onset?: string;
    public outcome: string;
    public outcomeDate?: string;
    public suspectDrugOrCause: string;
    public expectedness: string;
    public comments: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SeriousAdverseEvent>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public section: string;
    public type: string;
    public name: string;
    public path: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SurvivalFollowUp implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public consultationDate?: string;
    public followUpBy: string;
    public anyChangesSinceThePreviousAssessment: string;
    public trastuzumab: string;
    public endocrine: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurvivalFollowUp>) { (Object as any).assign(this, init); }
}

export class TargetMeasurableLesion implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public notDoneReason: string;
    public lesion1Measurement?: number;
    public lesion2Measurement?: number;
    public lesion3Measurement?: number;
    public lesion4Measurement?: number;
    public lesion5Measurement?: number;
    public sumOfAllTargetLesions?: number;
    public targetLesionResponse: string;
    public tmlAssessmentDate: string;
    public earliestTmlAssessmentDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TargetMeasurableLesion>) { (Object as any).assign(this, init); }
}

export class ThyroidFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public tsh?: number;
    public tshNotDone?: boolean;
    public tshLln?: number;
    public tshUln?: number;
    public t3?: number;
    public t3NotDone?: boolean;
    public t3Lln?: number;
    public t3Uln?: number;
    public t4?: number;
    public t4NotDone?: boolean;
    public t4Lln?: number;
    public t4Uln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ThyroidFunction>) { (Object as any).assign(this, init); }
}

export class TrastuzumabTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public weight?: number;
    public intendedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrastuzumabTreatment>) { (Object as any).assign(this, init); }
}

export class TremelimumabTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TremelimumabTreatment>) { (Object as any).assign(this, init); }
}

export class UnscheduledTrastuzumab implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public date?: string;
    public doseAdministered?: number;
    public intendedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UnscheduledTrastuzumab>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public medicalReviewId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    // @Ignore()
    public queryNumber?: number;

    public repeat?: number;
    public question: string;
    public response: string;
    public recipientId?: number;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class AdverseEventsAndSaes implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public cycleDates: DurvalumabTreatment[];

    // @Ignore()
    public adverseEvents: AdverseEvent[];

    // @Ignore()
    public seriousAdverseEvents: SeriousAdverseEvent[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEventsAndSaes>) { (Object as any).assign(this, init); }
}

export class BiopsyReports implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public cycle1Biopsy1NotDone?: boolean;
    public cycle1Biopsy1Date?: string;
    public cycle1Biopsy1Site: string;
    public cycle1Biopsy1SiteSpec: string;
    public cycle1Biopsy1Comments: string;
    public cycle1Biopsy1OestrogenNotDone?: boolean;
    public cycle1Biopsy1OestrogenReason: string;
    public cycle1Biopsy1OestrogenSign: string;
    public cycle1Biopsy1OestrogenPercentage?: number;
    public cycle1Biopsy1Ihc: string;
    public cycle1Biopsy1Ish: string;
    public cycle1Biopsy1IshNotDone?: boolean;
    public cycle1Biopsy1IshReason: string;
    public cycle1Biopsy1Erbb2?: number;
    public cycle1Biopsy1MeanGene?: number;
    public cycle1Biopsy1TilAssessed: string;
    public cycle1Biopsy1TilReason: string;
    public cycle1Biopsy1TilSign: string;
    public cycle1Biopsy1TilPercentage?: number;
    public cycle1Biopsy2NotDone?: boolean;
    public cycle1Biopsy2Date?: string;
    public cycle1Biopsy2Site: string;
    public cycle1Biopsy2SiteSpec: string;
    public cycle1Biopsy2Comments: string;
    public cycle1Biopsy2OestrogenNotDone?: boolean;
    public cycle1Biopsy2OestrogenReason: string;
    public cycle1Biopsy2OestrogenSign: string;
    public cycle1Biopsy2OestrogenPercentage?: number;
    public cycle1Biopsy2Ihc: string;
    public cycle1Biopsy2Ish: string;
    public cycle1Biopsy2IshNotDone?: boolean;
    public cycle1Biopsy2IshReason: string;
    public cycle1Biopsy2Erbb2?: number;
    public cycle1Biopsy2MeanGene?: number;
    public cycle1Biopsy2TilAssessed: string;
    public cycle1Biopsy2TilReason: string;
    public cycle1Biopsy2TilSign: string;
    public cycle1Biopsy2TilPercentage?: number;
    public progressionBiopsy1NotDone?: boolean;
    public progressionBiopsy1Date?: string;
    public progressionBiopsy1Site: string;
    public progressionBiopsy1SiteSpec: string;
    public progressionBiopsy1Comments: string;
    public progressionBiopsy1OestrogenNotDone?: boolean;
    public progressionBiopsy1OestrogenReason: string;
    public progressionBiopsy1OestrogenSign: string;
    public progressionBiopsy1OestrogenPercentage?: number;
    public progressionBiopsy1Ihc: string;
    public progressionBiopsy1Ish: string;
    public progressionBiopsy1IshNotDone?: boolean;
    public progressionBiopsy1IshReason: string;
    public progressionBiopsy1Erbb2?: number;
    public progressionBiopsy1MeanGene?: number;
    public progressionBiopsy1TilAssessed: string;
    public progressionBiopsy1TilReason: string;
    public progressionBiopsy1TilSign: string;
    public progressionBiopsy1TilPercentage?: number;
    public progressionBiopsy2NotDone?: boolean;
    public progressionBiopsy2Date?: string;
    public progressionBiopsy2Site: string;
    public progressionBiopsy2SiteSpec: string;
    public progressionBiopsy2Comments: string;
    public progressionBiopsy2OestrogenNotDone?: boolean;
    public progressionBiopsy2OestrogenReason: string;
    public progressionBiopsy2OestrogenSign: string;
    public progressionBiopsy2OestrogenPercentage?: number;
    public progressionBiopsy2Ihc: string;
    public progressionBiopsy2Ish: string;
    public progressionBiopsy2IshNotDone?: boolean;
    public progressionBiopsy2IshReason: string;
    public progressionBiopsy2Erbb2?: number;
    public progressionBiopsy2MeanGene?: number;
    public progressionBiopsy2TilAssessed: string;
    public progressionBiopsy2TilReason: string;
    public progressionBiopsy2TilSign: string;
    public progressionBiopsy2TilPercentage?: number;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BiopsyReports>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedications implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public baselineConcomitantMedications: ConcomitantMedicationBaseline[];

    // @Ignore()
    public onStudyConcomitantMedications: ConcomitantMedicationOnStudy[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedications>) { (Object as any).assign(this, init); }
}

export class Death implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfDeath?: string;
    public causeOfDeath: string;
    public causeOfDeathSpec: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Death>) { (Object as any).assign(this, init); }
}

export class FollowUp implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    // @Ignore()
    public survivalFollowUps: SurvivalFollowUp[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FollowUp>) { (Object as any).assign(this, init); }
}

export class GeneralInformation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waivered?: boolean;
    public waiverDate?: string;
    public waiverReason: string;
    public inevaluable?: boolean;
    public withdraw?: boolean;
    public withdrawDate?: string;
    public withdrawDecision: string;
    public agreesToContinueWithPlannedProtocolVisits: string;
    public agreesToBeContactedByTelephone: string;
    public agreesThatMyStudyDoctor: string;
    public agreesForAnyMandatoryBiological: string;
    public agreesForAnyOptionalBiological: string;
    public agreesForAnyMandatoryBiologicalSamples: string;
    public agreesForAnyOptionalBiologicalSamples: string;
    public wishesToWithdrawMyConsent: string;
    // @Ignore()
    public protocolDeviations: ProtocolDeviation[];

    // @Ignore()
    public comments: Comment[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GeneralInformation>) { (Object as any).assign(this, init); }
}

export class Histopathology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateDiagnosisTumour?: string;
    public primaryTumourSite1: string;
    public histopathologyTypeTumour1: string;
    public histopathologyTypeTumour1Spec: string;
    public histologicalGradeTumour1: string;
    public percentageStainedTumourSymbol1: string;
    public percentageStainedTumourValue1?: number;
    public ihcScoreTumour1: string;
    public ishScoreTumour1: string;
    public centromeresTumour1?: number;
    public centromeresTumour1NotAvailable?: boolean;
    public meanGeneCopyNumberTumour1?: number;
    public meanGeneCopyNumberTumour1NotAvailable?: boolean;
    public tumour2NotApplicable?: boolean;
    public primaryTumourSite2: string;
    public histopathologyTypeTumour2: string;
    public histopathologyTypeTumour2Spec: string;
    public histologicalGradeTumour2: string;
    public percentageStainedTumourSymbol2: string;
    public percentageStainedTumourValue2?: number;
    public ihcScoreTumour2: string;
    public ishScoreTumour2: string;
    public centromeresTumour2?: number;
    public centromeresTumour2NotAvailable?: boolean;
    public meanGeneCopyNumberTumour2?: number;
    public meanGeneCopyNumberTumour2NotAvailable?: boolean;
    public dateBiopsy1?: string;
    public biopsySite1: string;
    public biopsySite1Spec: string;
    public percentageStainedBiopsySymbol1: string;
    public percentageStainedBiopsyValue1?: number;
    public ihcScoreBiopsy1: string;
    public ishScoreBiopsy1: string;
    public centromeresBiopsy1?: number;
    public meanGeneCopyNumberBiopsy1?: number;
    public percentageTilCountBiopsySymbol1: string;
    public percentageTilCountBiopsyValue1?: number;
    public biopsy2Collected?: number;
    public dateBiopsy2?: string;
    public biopsySite2: string;
    public biopsySite2Spec: string;
    public percentageStainedBiopsySymbol2: string;
    public percentageStainedBiopsyValue2?: number;
    public ihcScoreBiopsy2: string;
    public ishScoreBiopsy2: string;
    public centromeresBiopsy2?: number;
    public meanGeneCopyNumberBiopsy2?: number;
    public percentageTilCountBiopsySymbol2: string;
    public percentageTilCountBiopsyValue2?: number;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Histopathology>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryAndPriorTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfFirstDiagnosis: string;
    // @Ignore()
    public medicalHistory: MedicalHistory[];

    // @Ignore()
    public concomitantMedications: ConcomitantMedication[];

    // @Ignore()
    public chemotherapy: Chemotherapy[];

    // @Ignore()
    public antiHer2Therapy: AntiHer2Therapy[];

    // @Ignore()
    public endocrineTherapy: EndocrineTherapy[];

    // @Ignore()
    public radiation: Radiation[];

    // @Ignore()
    public otherTreatments: OtherTreatment[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatment>) { (Object as any).assign(this, init); }
}

export class OrganFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public gilbertSyndrome: string;
    // @Ignore()
    public haematology: Haematology[];

    // @Ignore()
    public biochemistry: Biochemistry[];

    // @Ignore()
    public liverEnzymePanel: LiverEnzymePanel[];

    // @Ignore()
    public thyroidFunction: ThyroidFunction[];

    // @Ignore()
    public coagulation: Coagulation[];

    // @Ignore()
    public lvefAssessment: LvefAssessment[];

    // @Ignore()
    public ecg: Ecg[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OrganFunction>) { (Object as any).assign(this, init); }
}

export class OrganFunctionEligibility implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ecog?: number;
    public ecogUln?: number;
    public hbsag: string;
    public hcvAntibody: string;
    public hivAntigen: string;
    public ecg?: number;
    public ecgUln?: number;
    public echoMuga: string;
    public lvef?: number;
    public lvefLln?: number;
    public lvefEligibilityLln?: number;
    public lvefAssessmentDate?: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OrganFunctionEligibility>) { (Object as any).assign(this, init); }
}

export class Progression implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfProgression?: string;
    public methodForDetecting: string;
    public methodForDetectingSpec: string;
    public wasAConfirmatory: string;
    public wasAConfirmatoryReason: string;
    public dateOfConfirmation?: string;
    public methodOfConfirmation: string;
    public methodOfConfirmationSpec: string;
    public wasNewTreatment: string;
    public wasNewTreatmentSpec: string;
    public haveConfirmedProgression: string;
    public cnsDirectedTherapy: string;
    public cnsDirectedTherapySpec: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Progression>) { (Object as any).assign(this, init); }
}

export class Treatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dosesTremelimumabIp: string;
    public dosesDurvalumabIp: string;
    public dosesTrastuzumabIp: string;
    public dosesDurvalumabTp: string;
    public dosesTrastuzumabTp: string;
    public dosesTremelimumabSingleDose: string;
    public dosesDurvalumabSingleDose: string;
    public dosesTrastuzumabSingleDose: string;
    public treatmentCohort: string;
    public plannedStartDate?: string;
    public trastuzumabDiscontinued: string;
    public trastuzumabDiscontinuedSpec: string;
    public aromataseNotApplicable?: boolean;
    public goserelinNotApplicable?: boolean;
    // @Ignore()
    public tremelimumabCycles: TremelimumabTreatment[];

    // @Ignore()
    public durvalumabCycles: DurvalumabTreatment[];

    // @Ignore()
    public trastuzumabCycles: TrastuzumabTreatment[];

    // @Ignore()
    public unscheduledTrastuzumabs: UnscheduledTrastuzumab[];

    // @Ignore()
    public aromataseTreatments: AromataseTreatment[];

    // @Ignore()
    public goserelinTreatments: GoserelinTreatment[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class TumourAssessments implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public targetMeasurableLesion1Site: string;
    public targetMeasurableLesion1SiteSpec: string;
    public targetMeasurableLesion1OrganLocation: string;
    public targetMeasurableLesion2Site: string;
    public targetMeasurableLesion2SiteSpec: string;
    public targetMeasurableLesion2OrganLocation: string;
    public targetMeasurableLesion3Site: string;
    public targetMeasurableLesion3SiteSpec: string;
    public targetMeasurableLesion3OrganLocation: string;
    public targetMeasurableLesion4Site: string;
    public targetMeasurableLesion4SiteSpec: string;
    public targetMeasurableLesion4OrganLocation: string;
    public targetMeasurableLesion5Site: string;
    public targetMeasurableLesion5SiteSpec: string;
    public targetMeasurableLesion5OrganLocation: string;
    public nonMeasurableLesion1Site: string;
    public nonMeasurableLesion1SiteSpec: string;
    public nonMeasurableLesion1OrganLocation: string;
    public nonMeasurableLesion2Site: string;
    public nonMeasurableLesion2SiteSpec: string;
    public nonMeasurableLesion2OrganLocation: string;
    public nonMeasurableLesion3Site: string;
    public nonMeasurableLesion3SiteSpec: string;
    public nonMeasurableLesion3OrganLocation: string;
    public nonMeasurableLesion4Site: string;
    public nonMeasurableLesion4SiteSpec: string;
    public nonMeasurableLesion4OrganLocation: string;
    public nonMeasurableLesion5Site: string;
    public nonMeasurableLesion5SiteSpec: string;
    public nonMeasurableLesion5OrganLocation: string;
    // @Ignore()
    public targetMeasurableLesions: TargetMeasurableLesion[];

    // @Ignore()
    public nonMeasurableLesions: NonMeasurableLesion[];

    // @Ignore()
    public newLesions: NewLesion[];

    // @Ignore()
    public overallResponses: OverallResponse[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TumourAssessments>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class MedicalReviewPatient implements IMedicalReviewPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public medicalReviewers: number[];

    // @Ignore()
    public requiresAttention: number[];

    // @Ignore()
    public reviewStatus?: MedicalReviewStatus;

    public constructor(init?: Partial<MedicalReviewPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class MedicalReviewRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public reviewerIds: number[];
    public medicalReviewId?: number;
    public reviewerId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<MedicalReviewRequestContext>) { (Object as any).assign(this, init); }
}

export enum MedicalReviewPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewMedicalReview = 7,
    ViewMedicalReviewForm = 8,
    ViewQuery = 9,
    CreateMedicalReview = 10,
    CompleteMedicalReview = 11,
    CancelMedicalReview = 12,
    CompleteMedicalReviewForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    ViewAllPatients = 17,
    CancelQuery = 18,
    UpdateMedicalReview = 19,
}

export class Reviewer implements IReviewer
{
    public id?: number;
    public onTrial?: boolean;
    public masterGroups: { [index: number]: boolean; };
    public coordinatingGroups: { [index: number]: boolean; };
    public institutions: { [index: number]: boolean; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Reviewer>) { (Object as any).assign(this, init); }
}

export class MedicalReview implements IMedicalReview
{
    public id?: number;
    public patientId?: number;
    public medicalReviewDefinitionId?: number;
    public status?: MedicalReviewStatus;
    public reviewerId?: number;
    public repeat?: number;
    public startDate?: string;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewGetSingleByPatientIdAndRepeat extends IGet
{
    patientId?: number;
    repeat?: number;
}

export interface IMedicalReviewGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export class MedicalReviewCreationOptions
{
    public formDefinitionIds: number[];
    public includeUnchangedForms?: boolean;
    public compareFormsAcrossMedicalReviewDefinitions?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationOptions>) { (Object as any).assign(this, init); }
}

export class MedicalReviewUpdateOptions extends MedicalReviewCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<MedicalReviewUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IMedicalReviewPostUpdateReview extends IPost
{
    metadata: { [index: string]: Object; };
    medicalReviewId?: number;
    options: MedicalReviewUpdateOptions;
}

export interface IMedicalReviewPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export interface IMedicalReviewGetNextFormByIds extends IGet
{
    medicalReviewId?: number;
    currentFormDefinitionId?: number;
    currentFormRepeat?: number;
    metadata: { [index: string]: Object; };
    statuses: MedicalReviewStatus[];
}

export interface IQueryGetCollectionByMedicalReviewId extends IGet
{
    medicalReviewId?: number;
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export class MedicalReviewFormType extends FormType implements IMedicalReviewFormType
{
    public order?: number;

    public constructor(init?: Partial<MedicalReviewFormType>) { super(init); (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class MedicalReviewFormDefinition extends FormDefinition implements IMedicalReviewFormDefinition
{
    public order?: number;

    public constructor(init?: Partial<MedicalReviewFormDefinition>) { super(init); (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class MedicalReviewDefinition implements IMedicalReviewDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<MedicalReviewDefinition>) { (Object as any).assign(this, init); }
}

export class QueryRecipient implements IQueryRecipient
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<QueryRecipient>) { (Object as any).assign(this, init); }
}

export class NextMedicalReviewForm
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public formDefinitionName: string;
    public formTypeId?: number;
    public formTypeCode: string;
    public formTypeName: string;
    public formRepeat?: number;

    public constructor(init?: Partial<NextMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewNextFormResponse
{
    nextForm: NextMedicalReviewForm;
}

export interface IMedicalReviewForm extends IForm
{
    medicalReviewId?: number;
    medicalReviewStatus?: MedicalReviewStatus;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IMasterGroup extends IDataModel, IHasCode
{
    name: string;
}

export interface IHasCode
{
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId, IHasCode
{
    name: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel, IHasCode
{
    name: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId, IHasCode
{
    name: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IMedicalReviewPatient extends IPatient
{
    treatmentArm: string;
    medicalReviewers: number[];
    requiresAttention: number[];
    reviewStatus?: MedicalReviewStatus;
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IReviewer extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial?: boolean;
    masterGroups: { [index: number]: boolean; };
    coordinatingGroups: { [index: number]: boolean; };
    institutions: { [index: number]: boolean; };
}

export interface IMedicalReview extends IDataModel, IHasPatientId
{
    medicalReviewDefinitionId?: number;
    status?: MedicalReviewStatus;
    reviewerId?: number;
    repeat?: number;
    formDefinitionIds: number[];
    startDate?: string;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    medicalReviewId?: number;
    propertyName: string;
    parentQueryId?: number;
    queryNumber?: number;
    repeat?: number;
    question: string;
    response: string;
    recipientId?: number;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IMedicalReviewFormType extends IFormType
{
    order?: number;
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IMedicalReviewFormDefinition extends IFormDefinition
{
    order?: number;
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IMedicalReviewDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IQueryRecipient extends IConfigurationModel
{
    name: string;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class SendMedicalReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendMedicalReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: MedicalReviewPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdverseEvent[];

    public constructor(init?: Partial<AdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Annotation[];

    public constructor(init?: Partial<AnnotationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AnnotationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiHer2TherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiHer2TherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AntiHer2Therapy[];

    public constructor(init?: Partial<AntiHer2TherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiHer2TherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AntiHer2TherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AromataseTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AromataseTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AromataseTreatment[];

    public constructor(init?: Partial<AromataseTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AromataseTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AromataseTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Biochemistry[];

    public constructor(init?: Partial<BiochemistryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BiochemistryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChemotherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Chemotherapy;

    public constructor(init?: Partial<ChemotherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChemotherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Chemotherapy[];

    public constructor(init?: Partial<ChemotherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChemotherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ChemotherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Coagulation[];

    public constructor(init?: Partial<CoagulationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoagulationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Comment[];

    public constructor(init?: Partial<CommentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CommentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationBaselineSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselineSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationBaselineCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline[];

    public constructor(init?: Partial<ConcomitantMedicationBaselineCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationBaselineValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationBaselineValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationOnStudySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationOnStudyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy[];

    public constructor(init?: Partial<ConcomitantMedicationOnStudyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationOnStudyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CycleInfoSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CycleInfo;

    public constructor(init?: Partial<CycleInfoSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CycleInfoCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CycleInfo[];

    public constructor(init?: Partial<CycleInfoCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CycleInfoValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CycleInfoValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DurvalumabTreatment[];

    public constructor(init?: Partial<DurvalumabTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DurvalumabTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Ecg[];

    public constructor(init?: Partial<EcgCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<EcgValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndocrineTherapy[];

    public constructor(init?: Partial<EndocrineTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<EndocrineTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GoserelinTreatment[];

    public constructor(init?: Partial<GoserelinTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GoserelinTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Haematology[];

    public constructor(init?: Partial<HaematologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HaematologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LiverEnzymePanelSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LiverEnzymePanelCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LiverEnzymePanel[];

    public constructor(init?: Partial<LiverEnzymePanelCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LiverEnzymePanelValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LiverEnzymePanelValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LvefAssessmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LvefAssessmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LvefAssessment[];

    public constructor(init?: Partial<LvefAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LvefAssessmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LvefAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NewLesion[];

    public constructor(init?: Partial<NewLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NewLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonMeasurableLesion[];

    public constructor(init?: Partial<NonMeasurableLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NonMeasurableLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OtherTreatment;

    public constructor(init?: Partial<OtherTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OtherTreatment[];

    public constructor(init?: Partial<OtherTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OtherTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponseSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OverallResponse[];

    public constructor(init?: Partial<OverallResponseCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OverallResponseValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProtocolDeviation[];

    public constructor(init?: Partial<ProtocolDeviationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProtocolDeviationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Radiation;

    public constructor(init?: Partial<RadiationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Radiation[];

    public constructor(init?: Partial<RadiationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<RadiationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent[];

    public constructor(init?: Partial<SeriousAdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SeriousAdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurvivalFollowUp[];

    public constructor(init?: Partial<SurvivalFollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurvivalFollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion[];

    public constructor(init?: Partial<TargetMeasurableLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TargetMeasurableLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ThyroidFunction[];

    public constructor(init?: Partial<ThyroidFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ThyroidFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TrastuzumabTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TrastuzumabTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment[];

    public constructor(init?: Partial<TrastuzumabTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TrastuzumabTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TrastuzumabTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TremelimumabTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TremelimumabTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TremelimumabTreatment[];

    public constructor(init?: Partial<TremelimumabTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TremelimumabTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TremelimumabTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnscheduledTrastuzumabSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnscheduledTrastuzumabCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab[];

    public constructor(init?: Partial<UnscheduledTrastuzumabCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnscheduledTrastuzumabValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UnscheduledTrastuzumabValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsAndSaesSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdverseEventsAndSaes;

    public constructor(init?: Partial<AdverseEventsAndSaesSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsAndSaesCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdverseEventsAndSaes[];

    public constructor(init?: Partial<AdverseEventsAndSaesCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsAndSaesValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventsAndSaesValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiopsyReportsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BiopsyReports;

    public constructor(init?: Partial<BiopsyReportsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiopsyReportsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BiopsyReports[];

    public constructor(init?: Partial<BiopsyReportsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiopsyReportsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BiopsyReportsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedications[];

    public constructor(init?: Partial<ConcomitantMedicationsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Death[];

    public constructor(init?: Partial<DeathCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DeathValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FollowUpSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FollowUpCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: FollowUp[];

    public constructor(init?: Partial<FollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FollowUpValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<FollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralInformation[];

    public constructor(init?: Partial<GeneralInformationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GeneralInformationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Histopathology[];

    public constructor(init?: Partial<HistopathologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HistopathologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndPriorTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndPriorTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment[];

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndPriorTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OrganFunction[];

    public constructor(init?: Partial<OrganFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OrganFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionEligibilitySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OrganFunctionEligibility;

    public constructor(init?: Partial<OrganFunctionEligibilitySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionEligibilityCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OrganFunctionEligibility[];

    public constructor(init?: Partial<OrganFunctionEligibilityCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionEligibilityValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OrganFunctionEligibilityValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProgressionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProgressionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Progression[];

    public constructor(init?: Partial<ProgressionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProgressionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProgressionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourAssessmentsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourAssessmentsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TumourAssessments[];

    public constructor(init?: Partial<TumourAssessmentsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourAssessmentsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TumourAssessmentsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReviewPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition;

    public constructor(init?: Partial<MedicalReviewDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition[];

    public constructor(init?: Partial<MedicalReviewDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient;

    public constructor(init?: Partial<QueryRecipientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient[];

    public constructor(init?: Partial<QueryRecipientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer[];

    public constructor(init?: Partial<ReviewerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReviewerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview[];

    public constructor(init?: Partial<MedicalReviewCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalReviewValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCreationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=3)
    public hasNoForms?: boolean;

    // @DataMember(Order=4)
    public hasNoChanges?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<MedicalReviewValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewNextFormResponse implements IMedicalReviewNextFormResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public nextForm: NextMedicalReviewForm;

    public constructor(init?: Partial<MedicalReviewNextFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

export class QueueAvailableMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailableMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueAvailableMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class QueueStartedAndCompletedMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueStartedAndCompletedMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueStartedAndCompletedMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class SendMedicalReviewsAvailableEmails implements IReturn<SendMedicalReviewEmailResponse>
{

    public constructor(init?: Partial<SendMedicalReviewsAvailableEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewsAvailableEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendStartedAndCompletedMedicalReviewsEmail implements IReturn<SendMedicalReviewEmailResponse>
{

    public constructor(init?: Partial<SendStartedAndCompletedMedicalReviewsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStartedAndCompletedMedicalReviewsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendMedicalReviewCompleteEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public medicalReviewId?: number;

    public constructor(init?: Partial<SendMedicalReviewCompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewCompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/medical-review/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/emails/reviewer-query-summary/{ReviewerId}")
export class SendReviewerQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendReviewerQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendReviewerQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class QueueReviewerQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueReviewerQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueReviewerQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/queries", "GET")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/medical-review/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserGetSingle'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSingleResponse(); }
}

// @Route("/medical-review/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/medical-review/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

// @Route("/medical-review/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type - MedicalReviewFormType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/medical-review/form-type/collection", "GET")
// @Api(Description="Form Type - MedicalReviewFormType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeCollectionResponse(); }
}

// @Route("/medical-review/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type - MedicalReviewFormType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/medical-review/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition - MedicalReviewFormDefinition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/medical-review/form-definition/collection", "GET")
// @Api(Description="Form Definition - MedicalReviewFormDefinition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

// @Route("/medical-review/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition - MedicalReviewFormDefinition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/medical-review/form/adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleById implements IReturn<AdverseEventSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/adverse-event/collection", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollection implements IReturn<AdverseEventCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

// @Route("/medical-review/form/adverse-event/create", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostCreate implements IReturn<AdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/adverse-event/save", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSave implements IReturn<AdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/adverse-event/validate", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidate implements IReturn<AdverseEventValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

// @Route("/medical-review/form/adverse-event/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/adverse-event/single/update", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostUpdateById implements IReturn<AdverseEventSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/annotation/single/id/{Id}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleById implements IReturn<AnnotationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AnnotationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/collection", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetCollection implements IReturn<AnnotationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AnnotationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationCollectionResponse(); }
}

// @Route("/medical-review/form/annotation/create", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostCreate implements IReturn<AnnotationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/save", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostSave implements IReturn<AnnotationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/validate", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostValidate implements IReturn<AnnotationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationValidationResponse(); }
}

// @Route("/medical-review/form/annotation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleByMedicalReviewIdAndRepeat implements IReturn<AnnotationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AnnotationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/single/update", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostUpdateById implements IReturn<AnnotationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AnnotationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyGetSingleById implements IReturn<AntiHer2TherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AntiHer2TherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/collection", "GET")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyGetCollection implements IReturn<AntiHer2TherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AntiHer2TherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapyCollectionResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/create", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostCreate implements IReturn<AntiHer2TherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/save", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostSave implements IReturn<AntiHer2TherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/validate", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostValidate implements IReturn<AntiHer2TherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AntiHer2TherapyValidationResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<AntiHer2TherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AntiHer2TherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/medical-review/form/anti-her2-therapy/single/update", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostUpdateById implements IReturn<AntiHer2TherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AntiHer2TherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentGetSingleById implements IReturn<AromataseTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AromataseTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/collection", "GET")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentGetCollection implements IReturn<AromataseTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AromataseTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/create", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostCreate implements IReturn<AromataseTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/save", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostSave implements IReturn<AromataseTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/validate", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostValidate implements IReturn<AromataseTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AromataseTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<AromataseTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AromataseTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/aromatase-treatment/single/update", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostUpdateById implements IReturn<AromataseTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AromataseTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/biochemistry/single/id/{Id}", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetSingleById implements IReturn<BiochemistrySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BiochemistryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/medical-review/form/biochemistry/collection", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetCollection implements IReturn<BiochemistryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BiochemistryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistryCollectionResponse(); }
}

// @Route("/medical-review/form/biochemistry/create", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostCreate implements IReturn<BiochemistrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/medical-review/form/biochemistry/save", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostSave implements IReturn<BiochemistrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/medical-review/form/biochemistry/validate", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostValidate implements IReturn<BiochemistryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiochemistryValidationResponse(); }
}

// @Route("/medical-review/form/biochemistry/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetSingleByMedicalReviewIdAndRepeat implements IReturn<BiochemistrySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BiochemistryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/medical-review/form/biochemistry/single/update", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostUpdateById implements IReturn<BiochemistrySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BiochemistryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/medical-review/form/chemotherapy/single/id/{Id}", "GET")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyGetSingleById implements IReturn<ChemotherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ChemotherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/chemotherapy/collection", "GET")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyGetCollection implements IReturn<ChemotherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ChemotherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemotherapyCollectionResponse(); }
}

// @Route("/medical-review/form/chemotherapy/create", "POST")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyPostCreate implements IReturn<ChemotherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Chemotherapy;

    public constructor(init?: Partial<ChemotherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/chemotherapy/save", "POST")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyPostSave implements IReturn<ChemotherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Chemotherapy;

    public constructor(init?: Partial<ChemotherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/chemotherapy/validate", "POST")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemotherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapyValidationResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyPostValidate implements IReturn<ChemotherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Chemotherapy;

    public constructor(init?: Partial<ChemotherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChemotherapyValidationResponse(); }
}

// @Route("/medical-review/form/chemotherapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<ChemotherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ChemotherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/chemotherapy/single/update", "POST")
// @Api(Description="Form - Chemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChemotherapyPostUpdateById implements IReturn<ChemotherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ChemotherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemotherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/coagulation/single/id/{Id}", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetSingleById implements IReturn<CoagulationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoagulationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/medical-review/form/coagulation/collection", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetCollection implements IReturn<CoagulationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoagulationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationCollectionResponse(); }
}

// @Route("/medical-review/form/coagulation/create", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostCreate implements IReturn<CoagulationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/medical-review/form/coagulation/save", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostSave implements IReturn<CoagulationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/medical-review/form/coagulation/validate", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostValidate implements IReturn<CoagulationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationValidationResponse(); }
}

// @Route("/medical-review/form/coagulation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetSingleByMedicalReviewIdAndRepeat implements IReturn<CoagulationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CoagulationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/medical-review/form/coagulation/single/update", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostUpdateById implements IReturn<CoagulationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CoagulationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/medical-review/form/comment/single/id/{Id}", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentGetSingleById implements IReturn<CommentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CommentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/collection", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=500)
// @DataContract
export class CommentGetCollection implements IReturn<CommentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CommentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentCollectionResponse(); }
}

// @Route("/medical-review/form/comment/create", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostCreate implements IReturn<CommentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/save", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostSave implements IReturn<CommentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/validate", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=500)
// @DataContract
export class CommentPostValidate implements IReturn<CommentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentValidationResponse(); }
}

// @Route("/medical-review/form/comment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentGetSingleByMedicalReviewIdAndRepeat implements IReturn<CommentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CommentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/single/update", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostUpdateById implements IReturn<CommentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CommentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/create", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostCreate implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/save", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/single/update", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostUpdateById implements IReturn<ConcomitantMedicationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselineGetSingleById implements IReturn<ConcomitantMedicationBaselineSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationBaselineGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselineGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/collection", "GET")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselineGetCollection implements IReturn<ConcomitantMedicationBaselineCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationBaselineGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselineGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineCollectionResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/create", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostCreate implements IReturn<ConcomitantMedicationBaselineSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/save", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostSave implements IReturn<ConcomitantMedicationBaselineSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/validate", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostValidate implements IReturn<ConcomitantMedicationBaselineValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationBaselineValidationResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselineGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationBaselineSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationBaselineGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselineGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-baseline/single/update", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostUpdateById implements IReturn<ConcomitantMedicationBaselineSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyGetSingleById implements IReturn<ConcomitantMedicationOnStudySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/collection", "GET")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyGetCollection implements IReturn<ConcomitantMedicationOnStudyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudyCollectionResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/create", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostCreate implements IReturn<ConcomitantMedicationOnStudySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/save", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostSave implements IReturn<ConcomitantMedicationOnStudySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/validate", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostValidate implements IReturn<ConcomitantMedicationOnStudyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationOnStudyValidationResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationOnStudySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication-on-study/single/update", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostUpdateById implements IReturn<ConcomitantMedicationOnStudySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/medical-review/form/cycle-info/single/id/{Id}", "GET")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=500)
// @DataContract
export class CycleInfoGetSingleById implements IReturn<CycleInfoSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CycleInfoGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CycleInfoSingleResponse(); }
}

// @Route("/medical-review/form/cycle-info/collection", "GET")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoCollectionResponse)", StatusCode=500)
// @DataContract
export class CycleInfoGetCollection implements IReturn<CycleInfoCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CycleInfoGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CycleInfoCollectionResponse(); }
}

// @Route("/medical-review/form/cycle-info/create", "POST")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=500)
// @DataContract
export class CycleInfoPostCreate implements IReturn<CycleInfoSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CycleInfo;

    public constructor(init?: Partial<CycleInfoPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CycleInfoSingleResponse(); }
}

// @Route("/medical-review/form/cycle-info/save", "POST")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=500)
// @DataContract
export class CycleInfoPostSave implements IReturn<CycleInfoSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CycleInfo;

    public constructor(init?: Partial<CycleInfoPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CycleInfoSingleResponse(); }
}

// @Route("/medical-review/form/cycle-info/validate", "POST")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CycleInfoValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoValidationResponse)", StatusCode=500)
// @DataContract
export class CycleInfoPostValidate implements IReturn<CycleInfoValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CycleInfo;

    public constructor(init?: Partial<CycleInfoPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CycleInfoValidationResponse(); }
}

// @Route("/medical-review/form/cycle-info/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=500)
// @DataContract
export class CycleInfoGetSingleByMedicalReviewIdAndRepeat implements IReturn<CycleInfoSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CycleInfoGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CycleInfoSingleResponse(); }
}

// @Route("/medical-review/form/cycle-info/single/update", "POST")
// @Api(Description="Form - CycleInfo")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CycleInfoSingleResponse)", StatusCode=500)
// @DataContract
export class CycleInfoPostUpdateById implements IReturn<CycleInfoSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CycleInfoPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CycleInfoPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CycleInfoSingleResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentGetSingleById implements IReturn<DurvalumabTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DurvalumabTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/collection", "GET")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentGetCollection implements IReturn<DurvalumabTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DurvalumabTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/create", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostCreate implements IReturn<DurvalumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/save", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostSave implements IReturn<DurvalumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/validate", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostValidate implements IReturn<DurvalumabTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<DurvalumabTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DurvalumabTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/durvalumab-treatment/single/update", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostUpdateById implements IReturn<DurvalumabTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DurvalumabTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/ecg/single/id/{Id}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleById implements IReturn<EcgSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EcgGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/medical-review/form/ecg/collection", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=500)
// @DataContract
export class EcgGetCollection implements IReturn<EcgCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<EcgGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgCollectionResponse(); }
}

// @Route("/medical-review/form/ecg/create", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostCreate implements IReturn<EcgSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/medical-review/form/ecg/save", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostSave implements IReturn<EcgSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/medical-review/form/ecg/validate", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=500)
// @DataContract
export class EcgPostValidate implements IReturn<EcgValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgValidationResponse(); }
}

// @Route("/medical-review/form/ecg/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleByMedicalReviewIdAndRepeat implements IReturn<EcgSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<EcgGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/medical-review/form/ecg/single/update", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostUpdateById implements IReturn<EcgSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<EcgPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyGetSingleById implements IReturn<EndocrineTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EndocrineTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/collection", "GET")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyGetCollection implements IReturn<EndocrineTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<EndocrineTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/create", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostCreate implements IReturn<EndocrineTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/save", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostSave implements IReturn<EndocrineTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/validate", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostValidate implements IReturn<EndocrineTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapyValidationResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<EndocrineTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<EndocrineTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/single/update", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostUpdateById implements IReturn<EndocrineTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<EndocrineTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetSingleById implements IReturn<GoserelinTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/collection", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetCollection implements IReturn<GoserelinTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/create", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostCreate implements IReturn<GoserelinTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/save", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostSave implements IReturn<GoserelinTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/validate", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostValidate implements IReturn<GoserelinTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<GoserelinTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/single/update", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostUpdateById implements IReturn<GoserelinTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GoserelinTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/haematology/single/id/{Id}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleById implements IReturn<HaematologySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HaematologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/medical-review/form/haematology/collection", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetCollection implements IReturn<HaematologyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HaematologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologyCollectionResponse(); }
}

// @Route("/medical-review/form/haematology/create", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostCreate implements IReturn<HaematologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/medical-review/form/haematology/save", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostSave implements IReturn<HaematologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/medical-review/form/haematology/validate", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostValidate implements IReturn<HaematologyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologyValidationResponse(); }
}

// @Route("/medical-review/form/haematology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<HaematologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HaematologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/medical-review/form/haematology/single/update", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostUpdateById implements IReturn<HaematologySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HaematologyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/single/id/{Id}", "GET")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelGetSingleById implements IReturn<LiverEnzymePanelSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LiverEnzymePanelGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/collection", "GET")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelGetCollection implements IReturn<LiverEnzymePanelCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LiverEnzymePanelGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelCollectionResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/create", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostCreate implements IReturn<LiverEnzymePanelSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/save", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostSave implements IReturn<LiverEnzymePanelSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/validate", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostValidate implements IReturn<LiverEnzymePanelValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LiverEnzymePanelValidationResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelGetSingleByMedicalReviewIdAndRepeat implements IReturn<LiverEnzymePanelSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<LiverEnzymePanelGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/medical-review/form/liver-enzyme-panel/single/update", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostUpdateById implements IReturn<LiverEnzymePanelSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<LiverEnzymePanelPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentGetSingleById implements IReturn<LvefAssessmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LvefAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/collection", "GET")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentGetCollection implements IReturn<LvefAssessmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LvefAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentCollectionResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/create", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostCreate implements IReturn<LvefAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/save", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostSave implements IReturn<LvefAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/validate", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostValidate implements IReturn<LvefAssessmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LvefAssessmentValidationResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<LvefAssessmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<LvefAssessmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/medical-review/form/lvef-assessment/single/update", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostUpdateById implements IReturn<LvefAssessmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<LvefAssessmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/collection", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

// @Route("/medical-review/form/medical-history/create", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostCreate implements IReturn<MedicalHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/save", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/validate", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

// @Route("/medical-review/form/medical-history/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/single/update", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostUpdateById implements IReturn<MedicalHistorySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetSingleById implements IReturn<NewLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NewLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/collection", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetCollection implements IReturn<NewLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NewLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionCollectionResponse(); }
}

// @Route("/medical-review/form/new-lesion/create", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostCreate implements IReturn<NewLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/save", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostSave implements IReturn<NewLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/validate", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostValidate implements IReturn<NewLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionValidationResponse(); }
}

// @Route("/medical-review/form/new-lesion/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetSingleByMedicalReviewIdAndRepeat implements IReturn<NewLesionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NewLesionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/single/update", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostUpdateById implements IReturn<NewLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NewLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetSingleById implements IReturn<NonMeasurableLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/collection", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetCollection implements IReturn<NonMeasurableLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionCollectionResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/create", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostCreate implements IReturn<NonMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/save", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostSave implements IReturn<NonMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/validate", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostValidate implements IReturn<NonMeasurableLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionValidationResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetSingleByMedicalReviewIdAndRepeat implements IReturn<NonMeasurableLesionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/single/update", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostUpdateById implements IReturn<NonMeasurableLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NonMeasurableLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/other-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentGetSingleById implements IReturn<OtherTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/other-treatment/collection", "GET")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentGetCollection implements IReturn<OtherTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OtherTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/other-treatment/create", "POST")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentPostCreate implements IReturn<OtherTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherTreatment;

    public constructor(init?: Partial<OtherTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/other-treatment/save", "POST")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentPostSave implements IReturn<OtherTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherTreatment;

    public constructor(init?: Partial<OtherTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/other-treatment/validate", "POST")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentPostValidate implements IReturn<OtherTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherTreatment;

    public constructor(init?: Partial<OtherTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/other-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<OtherTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OtherTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/other-treatment/single/update", "POST")
// @Api(Description="Form - OtherTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OtherTreatmentPostUpdateById implements IReturn<OtherTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OtherTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/single/id/{Id}", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetSingleById implements IReturn<OverallResponseSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OverallResponseGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/collection", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetCollection implements IReturn<OverallResponseCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OverallResponseGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseCollectionResponse(); }
}

// @Route("/medical-review/form/overall-response/create", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostCreate implements IReturn<OverallResponseSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/save", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostSave implements IReturn<OverallResponseSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/validate", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostValidate implements IReturn<OverallResponseValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseValidationResponse(); }
}

// @Route("/medical-review/form/overall-response/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetSingleByMedicalReviewIdAndRepeat implements IReturn<OverallResponseSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OverallResponseGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/single/update", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostUpdateById implements IReturn<OverallResponseSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OverallResponsePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/id/{Id}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleById implements IReturn<ProtocolDeviationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/collection", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetCollection implements IReturn<ProtocolDeviationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProtocolDeviationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationCollectionResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/create", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostCreate implements IReturn<ProtocolDeviationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/save", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostSave implements IReturn<ProtocolDeviationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/validate", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostValidate implements IReturn<ProtocolDeviationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationValidationResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProtocolDeviationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/update", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostUpdateById implements IReturn<ProtocolDeviationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProtocolDeviationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/radiation/single/id/{Id}", "GET")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=500)
// @DataContract
export class RadiationGetSingleById implements IReturn<RadiationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<RadiationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationSingleResponse(); }
}

// @Route("/medical-review/form/radiation/collection", "GET")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationCollectionResponse)", StatusCode=500)
// @DataContract
export class RadiationGetCollection implements IReturn<RadiationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<RadiationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationCollectionResponse(); }
}

// @Route("/medical-review/form/radiation/create", "POST")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=500)
// @DataContract
export class RadiationPostCreate implements IReturn<RadiationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Radiation;

    public constructor(init?: Partial<RadiationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationSingleResponse(); }
}

// @Route("/medical-review/form/radiation/save", "POST")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=500)
// @DataContract
export class RadiationPostSave implements IReturn<RadiationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Radiation;

    public constructor(init?: Partial<RadiationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RadiationSingleResponse(); }
}

// @Route("/medical-review/form/radiation/validate", "POST")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationValidationResponse)", StatusCode=500)
// @DataContract
export class RadiationPostValidate implements IReturn<RadiationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Radiation;

    public constructor(init?: Partial<RadiationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RadiationValidationResponse(); }
}

// @Route("/medical-review/form/radiation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=500)
// @DataContract
export class RadiationGetSingleByMedicalReviewIdAndRepeat implements IReturn<RadiationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<RadiationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationSingleResponse(); }
}

// @Route("/medical-review/form/radiation/single/update", "POST")
// @Api(Description="Form - Radiation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationSingleResponse)", StatusCode=500)
// @DataContract
export class RadiationPostUpdateById implements IReturn<RadiationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<RadiationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RadiationSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetSingleById implements IReturn<SeriousAdverseEventSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/collection", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetCollection implements IReturn<SeriousAdverseEventCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventCollectionResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/create", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostCreate implements IReturn<SeriousAdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/save", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostSave implements IReturn<SeriousAdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/validate", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostValidate implements IReturn<SeriousAdverseEventValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventValidationResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetSingleByMedicalReviewIdAndRepeat implements IReturn<SeriousAdverseEventSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/single/update", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostUpdateById implements IReturn<SeriousAdverseEventSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SeriousAdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/collection", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/medical-review/form/source-document/create", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostCreate implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/save", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/validate", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/medical-review/form/source-document/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByMedicalReviewIdAndRepeat implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/single/update", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostUpdateById implements IReturn<SourceDocumentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SourceDocumentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetSingleById implements IReturn<SurvivalFollowUpSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/collection", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetCollection implements IReturn<SurvivalFollowUpCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpCollectionResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/create", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostCreate implements IReturn<SurvivalFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/save", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostSave implements IReturn<SurvivalFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/validate", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostValidate implements IReturn<SurvivalFollowUpValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpValidationResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurvivalFollowUpSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/survival-follow-up/single/update", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostUpdateById implements IReturn<SurvivalFollowUpSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurvivalFollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetSingleById implements IReturn<TargetMeasurableLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/collection", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetCollection implements IReturn<TargetMeasurableLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionCollectionResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/create", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostCreate implements IReturn<TargetMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/save", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostSave implements IReturn<TargetMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/validate", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostValidate implements IReturn<TargetMeasurableLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionValidationResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetSingleByMedicalReviewIdAndRepeat implements IReturn<TargetMeasurableLesionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/single/update", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostUpdateById implements IReturn<TargetMeasurableLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TargetMeasurableLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/thyroid-function/single/id/{Id}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleById implements IReturn<ThyroidFunctionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/medical-review/form/thyroid-function/collection", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetCollection implements IReturn<ThyroidFunctionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ThyroidFunctionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionCollectionResponse(); }
}

// @Route("/medical-review/form/thyroid-function/create", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostCreate implements IReturn<ThyroidFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/medical-review/form/thyroid-function/save", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostSave implements IReturn<ThyroidFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/medical-review/form/thyroid-function/validate", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostValidate implements IReturn<ThyroidFunctionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionValidationResponse(); }
}

// @Route("/medical-review/form/thyroid-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<ThyroidFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/medical-review/form/thyroid-function/single/update", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostUpdateById implements IReturn<ThyroidFunctionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ThyroidFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentGetSingleById implements IReturn<TrastuzumabTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TrastuzumabTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/collection", "GET")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentGetCollection implements IReturn<TrastuzumabTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TrastuzumabTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/create", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostCreate implements IReturn<TrastuzumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/save", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostSave implements IReturn<TrastuzumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/validate", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostValidate implements IReturn<TrastuzumabTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrastuzumabTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<TrastuzumabTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TrastuzumabTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/trastuzumab-treatment/single/update", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostUpdateById implements IReturn<TrastuzumabTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TrastuzumabTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentGetSingleById implements IReturn<TremelimumabTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TremelimumabTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/collection", "GET")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentGetCollection implements IReturn<TremelimumabTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TremelimumabTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/create", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostCreate implements IReturn<TremelimumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/save", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostSave implements IReturn<TremelimumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/validate", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostValidate implements IReturn<TremelimumabTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TremelimumabTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<TremelimumabTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TremelimumabTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tremelimumab-treatment/single/update", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostUpdateById implements IReturn<TremelimumabTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TremelimumabTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/single/id/{Id}", "GET")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabGetSingleById implements IReturn<UnscheduledTrastuzumabSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UnscheduledTrastuzumabGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/collection", "GET")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabGetCollection implements IReturn<UnscheduledTrastuzumabCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UnscheduledTrastuzumabGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabCollectionResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/create", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostCreate implements IReturn<UnscheduledTrastuzumabSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/save", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostSave implements IReturn<UnscheduledTrastuzumabSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/validate", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostValidate implements IReturn<UnscheduledTrastuzumabValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnscheduledTrastuzumabValidationResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabGetSingleByMedicalReviewIdAndRepeat implements IReturn<UnscheduledTrastuzumabSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<UnscheduledTrastuzumabGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/medical-review/form/unscheduled-trastuzumab/single/update", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostUpdateById implements IReturn<UnscheduledTrastuzumabSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UnscheduledTrastuzumabPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesGetSingleById implements IReturn<AdverseEventsAndSaesSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventsAndSaesGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsAndSaesSingleResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/collection", "GET")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesGetCollection implements IReturn<AdverseEventsAndSaesCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventsAndSaesGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsAndSaesCollectionResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/create", "POST")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesPostCreate implements IReturn<AdverseEventsAndSaesSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEventsAndSaes;

    public constructor(init?: Partial<AdverseEventsAndSaesPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsAndSaesSingleResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/save", "POST")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesPostSave implements IReturn<AdverseEventsAndSaesSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEventsAndSaes;

    public constructor(init?: Partial<AdverseEventsAndSaesPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventsAndSaesSingleResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/validate", "POST")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesPostValidate implements IReturn<AdverseEventsAndSaesValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEventsAndSaes;

    public constructor(init?: Partial<AdverseEventsAndSaesPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventsAndSaesValidationResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdverseEventsAndSaesSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventsAndSaesGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsAndSaesSingleResponse(); }
}

// @Route("/medical-review/form/adverse-events-and-saes/single/update", "POST")
// @Api(Description="Form - AdverseEventsAndSaes")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsAndSaesSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsAndSaesPostUpdateById implements IReturn<AdverseEventsAndSaesSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventsAndSaesPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsAndSaesPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventsAndSaesSingleResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/single/id/{Id}", "GET")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsGetSingleById implements IReturn<BiopsyReportsSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BiopsyReportsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiopsyReportsSingleResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/collection", "GET")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsCollectionResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsGetCollection implements IReturn<BiopsyReportsCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BiopsyReportsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiopsyReportsCollectionResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/create", "POST")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsPostCreate implements IReturn<BiopsyReportsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BiopsyReports;

    public constructor(init?: Partial<BiopsyReportsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiopsyReportsSingleResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/save", "POST")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsPostSave implements IReturn<BiopsyReportsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BiopsyReports;

    public constructor(init?: Partial<BiopsyReportsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiopsyReportsSingleResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/validate", "POST")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiopsyReportsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsValidationResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsPostValidate implements IReturn<BiopsyReportsValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BiopsyReports;

    public constructor(init?: Partial<BiopsyReportsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiopsyReportsValidationResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsGetSingleByMedicalReviewIdAndRepeat implements IReturn<BiopsyReportsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BiopsyReportsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiopsyReportsSingleResponse(); }
}

// @Route("/medical-review/form/biopsy-reports/single/update", "POST")
// @Api(Description="Form - BiopsyReports")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiopsyReportsSingleResponse)", StatusCode=500)
// @DataContract
export class BiopsyReportsPostUpdateById implements IReturn<BiopsyReportsSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BiopsyReportsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiopsyReportsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiopsyReportsSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetSingleById implements IReturn<ConcomitantMedicationsSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/collection", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetCollection implements IReturn<ConcomitantMedicationsCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsCollectionResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/create", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostCreate implements IReturn<ConcomitantMedicationsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/save", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostSave implements IReturn<ConcomitantMedicationsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/validate", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostValidate implements IReturn<ConcomitantMedicationsValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationsValidationResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medications/single/update", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostUpdateById implements IReturn<ConcomitantMedicationsSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

// @Route("/medical-review/form/death/single/id/{Id}", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathGetSingleById implements IReturn<DeathSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DeathGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/collection", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=500)
// @DataContract
export class DeathGetCollection implements IReturn<DeathCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DeathGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathCollectionResponse(); }
}

// @Route("/medical-review/form/death/create", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostCreate implements IReturn<DeathSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/save", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostSave implements IReturn<DeathSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/validate", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=500)
// @DataContract
export class DeathPostValidate implements IReturn<DeathValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathValidationResponse(); }
}

// @Route("/medical-review/form/death/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathGetSingleByMedicalReviewIdAndRepeat implements IReturn<DeathSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DeathGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/single/update", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostUpdateById implements IReturn<DeathSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DeathPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpGetSingleById implements IReturn<FollowUpSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/collection", "GET")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class FollowUpGetCollection implements IReturn<FollowUpCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpCollectionResponse(); }
}

// @Route("/medical-review/form/follow-up/create", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostCreate implements IReturn<FollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/save", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostSave implements IReturn<FollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/validate", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostValidate implements IReturn<FollowUpValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FollowUpValidationResponse(); }
}

// @Route("/medical-review/form/follow-up/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpGetSingleByMedicalReviewIdAndRepeat implements IReturn<FollowUpSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<FollowUpGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/single/update", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostUpdateById implements IReturn<FollowUpSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<FollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/general-information/single/id/{Id}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleById implements IReturn<GeneralInformationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/collection", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetCollection implements IReturn<GeneralInformationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GeneralInformationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationCollectionResponse(); }
}

// @Route("/medical-review/form/general-information/create", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostCreate implements IReturn<GeneralInformationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/save", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostSave implements IReturn<GeneralInformationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/validate", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostValidate implements IReturn<GeneralInformationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationValidationResponse(); }
}

// @Route("/medical-review/form/general-information/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleByMedicalReviewIdAndRepeat implements IReturn<GeneralInformationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/single/update", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostUpdateById implements IReturn<GeneralInformationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GeneralInformationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/histopathology/single/id/{Id}", "GET")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyGetSingleById implements IReturn<HistopathologySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HistopathologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

// @Route("/medical-review/form/histopathology/collection", "GET")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class HistopathologyGetCollection implements IReturn<HistopathologyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HistopathologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologyCollectionResponse(); }
}

// @Route("/medical-review/form/histopathology/create", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostCreate implements IReturn<HistopathologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

// @Route("/medical-review/form/histopathology/save", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostSave implements IReturn<HistopathologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

// @Route("/medical-review/form/histopathology/validate", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostValidate implements IReturn<HistopathologyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologyValidationResponse(); }
}

// @Route("/medical-review/form/histopathology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<HistopathologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HistopathologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

// @Route("/medical-review/form/histopathology/single/update", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostUpdateById implements IReturn<HistopathologySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HistopathologyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentGetSingleById implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/collection", "GET")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentGetCollection implements IReturn<MedicalHistoryAndPriorTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/create", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostCreate implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/save", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostSave implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/validate", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostValidate implements IReturn<MedicalHistoryAndPriorTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/single/update", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostUpdateById implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/organ-function/single/id/{Id}", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetSingleById implements IReturn<OrganFunctionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OrganFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

// @Route("/medical-review/form/organ-function/collection", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetCollection implements IReturn<OrganFunctionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OrganFunctionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionCollectionResponse(); }
}

// @Route("/medical-review/form/organ-function/create", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostCreate implements IReturn<OrganFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

// @Route("/medical-review/form/organ-function/save", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostSave implements IReturn<OrganFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

// @Route("/medical-review/form/organ-function/validate", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostValidate implements IReturn<OrganFunctionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionValidationResponse(); }
}

// @Route("/medical-review/form/organ-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<OrganFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OrganFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

// @Route("/medical-review/form/organ-function/single/update", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostUpdateById implements IReturn<OrganFunctionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OrganFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/single/id/{Id}", "GET")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityGetSingleById implements IReturn<OrganFunctionEligibilitySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OrganFunctionEligibilityGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionEligibilitySingleResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/collection", "GET")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityCollectionResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityGetCollection implements IReturn<OrganFunctionEligibilityCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OrganFunctionEligibilityGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionEligibilityCollectionResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/create", "POST")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityPostCreate implements IReturn<OrganFunctionEligibilitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OrganFunctionEligibility;

    public constructor(init?: Partial<OrganFunctionEligibilityPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionEligibilitySingleResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/save", "POST")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityPostSave implements IReturn<OrganFunctionEligibilitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OrganFunctionEligibility;

    public constructor(init?: Partial<OrganFunctionEligibilityPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionEligibilitySingleResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/validate", "POST")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilityValidationResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityPostValidate implements IReturn<OrganFunctionEligibilityValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OrganFunctionEligibility;

    public constructor(init?: Partial<OrganFunctionEligibilityPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionEligibilityValidationResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityGetSingleByMedicalReviewIdAndRepeat implements IReturn<OrganFunctionEligibilitySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OrganFunctionEligibilityGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionEligibilitySingleResponse(); }
}

// @Route("/medical-review/form/organ-function-eligibility/single/update", "POST")
// @Api(Description="Form - OrganFunctionEligibility")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionEligibilitySingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionEligibilityPostUpdateById implements IReturn<OrganFunctionEligibilitySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OrganFunctionEligibilityPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionEligibilityPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionEligibilitySingleResponse(); }
}

// @Route("/medical-review/form/progression/single/id/{Id}", "GET")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionGetSingleById implements IReturn<ProgressionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProgressionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/collection", "GET")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=500)
// @DataContract
export class ProgressionGetCollection implements IReturn<ProgressionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProgressionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionCollectionResponse(); }
}

// @Route("/medical-review/form/progression/create", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostCreate implements IReturn<ProgressionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/save", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostSave implements IReturn<ProgressionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/validate", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostValidate implements IReturn<ProgressionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProgressionValidationResponse(); }
}

// @Route("/medical-review/form/progression/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProgressionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProgressionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/single/update", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostUpdateById implements IReturn<ProgressionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProgressionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/treatment/single/id/{Id}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/collection", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/treatment/create", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostCreate implements IReturn<TreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/save", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostSave implements IReturn<TreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/validate", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostValidate implements IReturn<TreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentValidationResponse(); }
}

// @Route("/medical-review/form/treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<TreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/single/update", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostUpdateById implements IReturn<TreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/single/id/{Id}", "GET")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsGetSingleById implements IReturn<TumourAssessmentsSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourAssessmentsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/collection", "GET")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsGetCollection implements IReturn<TumourAssessmentsCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TumourAssessmentsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsCollectionResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/create", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostCreate implements IReturn<TumourAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/save", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostSave implements IReturn<TumourAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/validate", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostValidate implements IReturn<TumourAssessmentsValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourAssessmentsValidationResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsGetSingleByMedicalReviewIdAndRepeat implements IReturn<TumourAssessmentsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TumourAssessmentsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/single/update", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostUpdateById implements IReturn<TumourAssessmentsSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TumourAssessmentsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

// @Route("/medical-review/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupValidationResponse(); }
}

// @Route("/medical-review/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
}

// @Route("/medical-review/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
}

// @Route("/medical-review/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/medical-review/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountryValidationResponse(); }
}

// @Route("/medical-review/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/medical-review/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionValidationResponse(); }
}

// @Route("/medical-review/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/collection", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/medical-review/patient/create", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/save", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/validate", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/medical-review/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: MedicalReviewRequestContext;
    public permissions: MedicalReviewPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/id/{Id}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleById implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/medical-review-definition/collection", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetCollection implements IReturn<MedicalReviewDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionCollectionResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/code/{Code}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleByCode implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/query-recipient/single/id/{Id}", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetSingleById implements IReturn<QueryRecipientSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryRecipientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientSingleResponse(); }
}

// @Route("/medical-review/query-recipient/collection", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetCollection implements IReturn<QueryRecipientCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryRecipientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientCollectionResponse(); }
}

// @Route("/medical-review/reviewer/single/id/{Id}", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetSingleById implements IReturn<ReviewerSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReviewerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/collection", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetCollection implements IReturn<ReviewerCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReviewerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerCollectionResponse(); }
}

// @Route("/medical-review/reviewer/create", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostCreate implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/save", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostSave implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/validate", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostValidate implements IReturn<ReviewerValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/id/{Id}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleById implements IReturn<MedicalReviewSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollection implements IReturn<MedicalReviewCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreate implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/save", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostSave implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/validate", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostValidate implements IReturn<MedicalReviewValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleByPatientIdAndRepeat implements IReturn<MedicalReviewSingleResponse>, IMedicalReviewGetSingleByPatientIdAndRepeat
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleByPatientIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollectionByPatientId implements IReturn<MedicalReviewCollectionResponse>, IMedicalReviewGetCollectionByPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<MedicalReviewGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreateReview implements IReturn<MedicalReviewCreationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=2)
    public options: MedicalReviewCreationOptions;

    public constructor(init?: Partial<MedicalReviewPostCreateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/update-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostUpdateReview implements IReturn<MedicalReviewCreationSingleResponse>, IMedicalReviewPostUpdateReview
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public options: MedicalReviewUpdateOptions;

    public constructor(init?: Partial<MedicalReviewPostUpdateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostUpdateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/validate-forms", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostFormValidationCollectionById implements IReturn<MedicalReviewValidationCollectionResponse>, IMedicalReviewPostFormValidationCollectionById
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationCollectionResponse(); }
}

// @Route("/medical-review/medical-review/{MedicalReviewId}/next-form", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetNextFormByIds implements IReturn<MedicalReviewNextFormResponse>, IMedicalReviewGetNextFormByIds
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=1)
    public currentFormDefinitionId?: number;

    // @DataMember(Order=1)
    public currentFormRepeat?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public statuses: MedicalReviewStatus[];

    public constructor(init?: Partial<MedicalReviewGetNextFormByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetNextFormByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewNextFormResponse(); }
}

// @Route("/medical-review/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

// @Route("/medical-review/query/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByMedicalReviewId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByMedicalReviewId
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryGetCollectionByMedicalReviewId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByMedicalReviewId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

