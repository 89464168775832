import React, { FunctionComponent, useContext } from 'react';
import { FormsContext, FormsLayout, useAuthenticatedUser } from '@ngt/forms';
import { Navigate, Route, Routes, useRoutes, RouteObject, Outlet } from 'react-router-dom';
import { Patients, Patient, MedicalReview, MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute, MedicalReviewExtensionContext, IMedicalReviewFormMapping, MedicalReviewsByPatientStudyNumberRoute, MedicalReviewByPatientStudyNumberAndRepeatRoute, MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute, QuerySummary } from '@ngt/forms-medical-review';
import { TrialsExtensionContext, useInstitutions } from '@ngt/forms-trials';
import GeneralInformation from './containers/form/GeneralInformation';
import Histopathology from './containers/form/Histopathology';
import Treatment from './containers/form/Treatment';
import OrganFunctionEligibility from './containers/form/OrganFunctionEligibility';
import MedicalHistoryAndPriorTreatment from './containers/form/MedicalHistoryAndPriorTreatment';
import AdverseEventsAndSaes from './containers/form/AdverseEventsAndSaes';
import ConcomitantMedications from './containers/form/ConcomitantMedications';
import Progression from './containers/form/Progression';
import Death from './containers/form/Death';
import OrganFunction from './containers/form/OrganFunction';
import BiopsyReports from './containers/form/BiopsyReports';
import TumourAssessments from './containers/form/TumourAssessments';
import IFramePage from './containers/IFramePage';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';
import FollowUp from './containers/form/FollowUp';

//import IFramePage from './containers/IFramePage';

const formMapping: Record<string, IMedicalReviewFormMapping> = {
    "general-information": {
        components: <GeneralInformation />
    },
    "histopathology": {
        components: <Histopathology />
    },
    "treatment": {
        components: <Treatment />
    },
    "organ-function-eligibility": {
        components: <OrganFunctionEligibility />
    },
    "medical-history-and-prior-treatment": {
        components: <MedicalHistoryAndPriorTreatment />
    },
    "adverse-events-and-saes": {
        components: <AdverseEventsAndSaes />
    },
    "concomitant-medications": {
        components: <ConcomitantMedications />
    },
    "progression": {
        components: <Progression />
    },
    "death": {
        components: <Death />
    },
    "organ-function": {
        components: <OrganFunction />
    },
    "biopsy-reports": {
        components: <BiopsyReports />
    },
    "tumour-assessments": {
        components: <TumourAssessments />
    },
    "follow-up": {
        components: <FollowUp />
    }
}

const AppRoutes: FunctionComponent = () => {
    const { loading, error } = useInstitutions();

    if (!loading && error) {
        return (
            <ErrorPage errorType={parseInt(error.errorCode) ?? ErrorTypeEnum.e500} />
        )
    }

    return (
        <Routes>
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/:formRepeat/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            /> 
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/queries/*`}
                element={
                    <MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <QuerySummary />
                    </MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/*`}
                element={
                    <MedicalReviewByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <MedicalReview />
                    </MedicalReviewByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route 
                path={`/medical-review/:patientStudyNumber/*`}
                element={
                    <MedicalReviewsByPatientStudyNumberRoute
                        resolveBeforeLoad
                    >
                        <Patient />
                    </MedicalReviewsByPatientStudyNumberRoute>
                }
            />
            <Route
                path="/query-report/queries"
                element={
                    <IFramePage url="/queries" />
                }
            />
            <Route 
                path="/medical-review"
                element={<Patients />}
            />
            <Route path="*" element={<Navigate replace to="/medical-review" />} />
        </Routes>
    )
}

const App: FunctionComponent = () => {
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const medicalReviewExtensionContext = useContext(MedicalReviewExtensionContext);

    return (
        <FormsLayout>
            <AppRoutes />
        </FormsLayout>
    );
};

export default App;
