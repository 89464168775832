import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, SymbolDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, FieldProvider, FormLabel, NumericDisplay, BooleanDisplay } from '@ngt/forms';
import { Histopathology as HistopathologyDto, ProtocolDeviation } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import CoalesceDisplay from './input/display/CoalesceDisplay';
import Annotations from 'src/components/form/Annotations';
import SourceDocuments from 'src/components/form/SourceDocuments';
import ValueWithSpecificationDisplay from './input/display/ValueWithSpecificationDisplay';
import IfElseDisplay from './input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';

interface IHistopathologyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    greyRow:{
        '& td': {
            backgroundColor: theme.palette.grey[200]
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


//Conditions
const hasSecondBiopsy: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.biopsy2Collected === 1;

const hasTilBiopsy1: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.percentageTilCountBiopsyValue1 != null;
const notHasTilBiopsy1: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.percentageTilCountBiopsyValue1 == null;
const hasTilBiopsy2: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.percentageTilCountBiopsyValue2 != null;
const notHasTilBiopsy2: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.percentageTilCountBiopsyValue2 == null && !!value?.biopsy2Collected;

//Accessors

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;

const conditionSubscription = { value: true };



const Histopathology: FunctionComponent<IHistopathologyProps> = () => {
    const {classes} = useStyles();

    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            title: {
                padding: theme.spacing(2, 2, 2),
                color: theme.palette.secondary.main
            }, 
            titleContainer: {
                padding: theme.spacing(2, 2, 2),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }, 
            subtitle: {
                padding: theme.spacing(2, 2, 0)
            },
            patientInformation: {
                padding: theme.spacing(2, 2, 0)
            },
            alert: {
                padding: theme.spacing(2)
            },
            protocolDeviations: {
                padding: theme.spacing(2)
            },
            formPadding: {
                padding: theme.spacing(2)
            },
            buttonGroup: {
                padding: theme.spacing(2),
                textAlign: 'right'
            },
            noBorderRow: {
                border: `${0} !important`,

                '& > *': {
                    border: `${0} !important`
                }
            },
            greyRow: {
                '& td': {
                    backgroundColor: theme.palette.grey[200]
                }
            }
        }
    }, [theme]);

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <Box sx={styles.patientInformation}>
                <ContextPatientInformation />
            </Box>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <Box
                    sx={{
                        paddingBottom: 4
                    }}
                >
                    <Typography variant='h2' sx={styles.title}>
                        PRIMARY SPECIMENS
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        width: '440px'
                                    }}
                                >
                                </TableCell>
                                <TableCell>
                                    <Typography variant='h3'>
                                        Primary Tumour 1
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='h3'>
                                        Primary Tumour 2
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={styles.noBorderRow}>
                                <TableCell>
                                    <FieldProvider name="dateDiagnosisTumour">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="dateDiagnosisTumour"
                                        component={DateDisplay}
                                    />
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                            <TableRow sx={styles.noBorderRow}>
                                <TableCell>
                                    <FieldProvider name="primaryTumourSite1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="primaryTumourSite1"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="tumour2NotApplicable"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => !!value}
                                        ifTableCell
                                        ifName="tumour2NotApplicable"
                                        ifAccessor={(value: boolean) => "Not Applicable"}
                                        elseTableCell
                                        elseName="primaryTumourSite2"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={styles.noBorderRow}>
                                <TableCell>
                                    <FieldProvider name="histopathologyTypeTumour1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="histopathologyTypeTumour1"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="histopathologyTypeTumour1Spec"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="histopathologyTypeTumour2"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="histopathologyTypeTumour2Spec"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="histologicalGradeTumour1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="histologicalGradeTumour1"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="histologicalGradeTumour2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow}}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        Receptor Status (Oestrogen Receptor)
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{...styles.greyRow}}>
                                <TableCell>
                                    <FieldProvider name="percentageStainedTumourSymbol1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="percentageStainedTumourValue1"
                                        component={SymbolDisplay}
                                        symbolName="percentageStainedTumourSymbol1"
                                        suffix="%"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="percentageStainedTumourValue2"
                                        component={SymbolDisplay}
                                        symbolName="percentageStainedTumourSymbol2"
                                        suffix="%"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={styles.noBorderRow}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        HER2
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={styles.noBorderRow}>
                                <TableCell>
                                    <FieldProvider name="ihcScoreTumour1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ihcScoreTumour1"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ihcScoreTumour2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="ishScoreTumour1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ishScoreTumour1"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ishScoreTumour2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow }}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        ERBB2 Amplification
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="centromeresTumour1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="centromeresTumour1NotAvailable"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => !!value}
                                        ifTableCell
                                        ifName="centromeresTumour1NotAvailable"
                                        ifAccessor={(value: boolean) => "Not Available"}
                                        elseTableCell
                                        elseName="centromeresTumour1"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="centromeresTumour2NotAvailable"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => !!value}
                                        ifTableCell
                                        ifName="centromeresTumour2NotAvailable"
                                        ifAccessor={(value: boolean) => "Not Available"}
                                        elseTableCell
                                        elseName="centromeresTumour2"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={styles.greyRow}>
                                <TableCell>
                                    <FieldProvider name="meanGeneCopyNumberTumour1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="meanGeneCopyNumberTumour1NotAvailable"
                                        component={IfElseDisplay}
                                        condition={(value: number) => !!value}
                                        ifTableCell
                                        ifName="meanGeneCopyNumberTumour1NotAvailable"
                                        ifAccessor={(value: number) => "Not Available"}
                                        elseTableCell
                                        elseName="meanGeneCopyNumberTumour1"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="meanGeneCopyNumberTumour2NotAvailable"
                                        component={IfElseDisplay}
                                        condition={(value: number) => !!value}
                                        ifTableCell
                                        ifName="meanGeneCopyNumberTumour2NotAvailable"
                                        ifAccessor={(value: number) => "Not Available"}
                                        elseTableCell
                                        elseName="meanGeneCopyNumberTumour2"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Box>
                    <Typography variant='h2' sx={styles.title}>
                        SCREENING SPECIMENS (RESEARCH CORE BIOPSIES) 
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        width: '440px'
                                    }}
                                >
                                </TableCell>
                                <TableCell>
                                    <Typography variant='h3'>
                                        Biopsy 1
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='h3'>
                                        Biopsy 2
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="dateBiopsy1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="dateBiopsy1"
                                        component={DateDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="biopsy2Collected"
                                        component={IfElseDisplay}
                                        condition={(value: number) => value === 0 ? true : false}
                                        ifTableCell
                                        ifName="biopsy2Collected"
                                        ifAccessor={(value: number) => "Not Collected"}
                                        elseTableCell
                                        elseName="dateBiopsy2"
                                        elseAccessor={dateAccessor}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="biopsySite1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="biopsySite1"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="biopsySite1Spec"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="biopsySite2"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="biopsySite2Spec"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        Receptor Status (Oestrogen Receptor)
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="percentageStainedBiopsySymbol1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="percentageStainedBiopsyValue1"
                                        component={SymbolDisplay}
                                        symbolName="percentageStainedBiopsySymbol1"
                                        suffix="%"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="percentageStainedBiopsyValue2"
                                        component={SymbolDisplay}
                                        symbolName="percentageStainedBiopsySymbol2"
                                        suffix="%"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow }}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        HER2
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="ihcScoreBiopsy1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ihcScoreBiopsy1"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ihcScoreBiopsy2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="ishScoreBiopsy1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ishScoreBiopsy1"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="ishScoreBiopsy2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        ERBB2 Amplification
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="centromeresBiopsy1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="centromeresBiopsy1"
                                        component={IfElseDisplay}
                                        condition={(value: number) => value == null ? true : false}
                                        ifTableCell
                                        ifName="centromeresBiopsy1"
                                        ifAccessor={(value: number) => "Not Available"}
                                        elseTableCell
                                        elseName="centromeresBiopsy1"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={HistopathologyDto}
                                        condition={hasSecondBiopsy}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="centromeresBiopsy2"
                                            component={IfElseDisplay}
                                            condition={(value: number) => value == null ? true : false}
                                            ifTableCell
                                            ifName="centromeresBiopsy2"
                                            ifAccessor={(value: number) => "Not Available"}
                                            elseTableCell
                                            elseName="centromeresBiopsy2"
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="meanGeneCopyNumberBiopsy1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="meanGeneCopyNumberBiopsy1"
                                        component={IfElseDisplay}
                                        condition={(value: number) => value == null ? true : false}
                                        ifTableCell
                                        ifName="meanGeneCopyNumberBiopsy1"
                                        ifAccessor={(value: number) => "Not Available"}
                                        elseTableCell
                                        elseName="meanGeneCopyNumberBiopsy1"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={HistopathologyDto}
                                        condition={hasSecondBiopsy}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="meanGeneCopyNumberBiopsy2"
                                            component={IfElseDisplay}
                                            condition={(value: number) => value == null ? true : false}
                                            ifTableCell
                                            ifName="meanGeneCopyNumberBiopsy2"
                                            ifAccessor={(value: number) => "Not Available"}
                                            elseTableCell
                                            elseName="meanGeneCopyNumberBiopsy2"
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow, ...styles.noBorderRow }}>
                                <TableCell>
                                    <Typography variant='h4'>
                                        TILs
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="percentageTilCountBiopsySymbol1">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={HistopathologyDto}
                                        condition={notHasTilBiopsy1}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <Typography variant="body1">
                                            Not Done
                                        </Typography>
                                    </Condition>
                                    <Condition
                                        type={HistopathologyDto}
                                        condition={hasTilBiopsy1}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="percentageTilCountBiopsyValue1"
                                            component={SymbolDisplay}
                                            symbolName="percentageTilCountBiopsySymbol1"
                                            suffix="%"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                <Condition
                                        type={HistopathologyDto}
                                        condition={notHasTilBiopsy2}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <Typography variant="body1">
                                            Not Done
                                        </Typography>
                                    </Condition>
                                    <Condition
                                        type={HistopathologyDto}
                                        condition={hasTilBiopsy2}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="percentageTilCountBiopsyValue2"
                                            component={SymbolDisplay}
                                            symbolName="percentageTilCountBiopsySymbol2"
                                            suffix="%"
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Histopathology;
