/**
 * ------------------------------------------------------------------
 * Imports - External
 * ------------------------------------------------------------------
 */
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FC, useMemo } from 'react';

/**
 * ------------------------------------------------------------------
 * Enumerations
 * ------------------------------------------------------------------
 */
export enum ErrorTypeEnum {
    e403 = 403,
    e404 = 404,
    e500 = 500
}

/**
 * ------------------------------------------------------------------
 * Interfaces
 * ------------------------------------------------------------------
 */
interface IErrorPageProps {
    errorType: ErrorTypeEnum;
    errorMessage?: string;
}

/**
 * ------------------------------------------------------------------
 * Components
 * ------------------------------------------------------------------
 */
const ErrorPage: FC<IErrorPageProps> = ({
    errorType,
    errorMessage
}) => {

    const error = useMemo(() => {
        return {
            type: errorType,
            message: !errorMessage ?
                errorType === ErrorTypeEnum.e403 ?
                    'Access Denied' :
                    'Not Found':
                errorMessage
        }
    }, [errorType, errorMessage])

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 347.75px)',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <FontAwesomeIcon icon={faExclamationTriangle} color='red' fontSize='6rem' />
            <Typography variant="h1">{error.type}</Typography>
            <Typography variant="h4">{error.message}</Typography>
        </Box>
    )
}

export default ErrorPage