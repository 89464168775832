import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, IValidationError, ITextDisplayProps } from '@ngt/forms';
import { GeneralInformation as GeneralInformationDto, ProtocolDeviation, Comment, MedicalReviewStatus } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';

interface IGeneralInformationProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNoWaiver: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.waivered;
const hasWaiver: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.waivered;

const hasNoDeviations: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.protocolDeviations || value?.protocolDeviations?.length === 0;
const hasDeviations: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.protocolDeviations && value?.protocolDeviations?.length > 0;


const hasNoWithdrawalForm: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.withdraw !== true;
const hasWithdrawalForm: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.withdraw === true;

const hasNoComments: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.comments || value?.comments?.length === 0;
const hasComments: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.comments && value?.comments?.length > 0;

const conditionSubscription = { value: true };

const protocoldeviationColumns: IGroupedSubformGridCell<ProtocolDeviation>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        width: 150,
        sortValue: (row) => row?.eventName
    },
    {
        name: 'crfName',
        content: (
            <Input component={TextDisplay} />
        ),
        width: 250,
        sortValue: (row) => row?.crfName
    },
    {
        name: 'description',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        width: 550,
        sortValue: (row) => row?.description
    },
    {
        name: 'detailedNote',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.detailedNote
    }
];

const commentColumns: IGroupedSubformGridCell<Comment>[] = [
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.date
    },
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'crfName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.crfName
    },
    {
        name: 'commentText',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.commentText
    }
];

const WithdrawalTextDisplay: FunctionComponent<ITextDisplayProps> = (props) => {
    const {
        inputRender: {
            state: {
                value
            }
        }
    } = props;

    if (!value) {
        return null;
    }

    return (
        <Grid
            container
            sx={{
                px: 2,
                py: 1
            }}
        >
            <Grid
                item
                {...groupStyleProps.labelColumn}
            >

            </Grid>
            <Grid
                item
                {...groupStyleProps.inputColumn}
            >
                <TextDisplay {...props} />
            </Grid>
        </Grid>
    )
}

const GeneralInformation: FunctionComponent<IGeneralInformationProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Protocol Waiver
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasWaiver}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="waiverDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="waiverReason"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoWaiver}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Protocol Waiver"
                                description="There is no protocol waiver associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Protocol Deviations
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasDeviations}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ProtocolDeviation}
                            name="protocolDeviations"
                            columns={protocoldeviationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoDeviations}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Protocol Deviations"
                                description="There is no protocol deviation associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>


                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Withdrawal of Participation
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasWithdrawalForm}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        
                        <GroupedField
                            name="withdrawDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        
                        <GroupedField
                            name="withdrawDecision"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        
                        <InputOnlyField
                            name="agreesToContinueWithPlannedProtocolVisits"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="agreesToBeContactedByTelephone"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="agreesThatMyStudyDoctor"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="agreesForAnyMandatoryBiological"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="agreesForAnyOptionalBiological"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="agreesForAnyMandatoryBiologicalSamples"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="agreesForAnyOptionalBiologicalSamples"
                            component={WithdrawalTextDisplay}
                        />
                        
                        <InputOnlyField
                            name="wishesToWithdrawMyConsent"
                            component={WithdrawalTextDisplay}
                        />
                        
                        

                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoWithdrawalForm}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Withdrawal Form"
                                description="There is no protocol Withdrawal Form with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>


                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Comments and Issues
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasComments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Comment}
                            name="comments"
                            columns={commentColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoComments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Issues or Comments"
                                description="There are no issues or comments associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />

            </ContextMedicalReviewForm>
        </>
    );
};

export default GeneralInformation;
