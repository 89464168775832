import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { GroupedFields, Input, TextDisplay, InputOnlyField } from '@ngt/forms';
import { SourceDocument } from '../../apis/dtos';
import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography, useTheme } from '@mui/material';
import { Condition, CountDisplay, ICondition, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFileDisplay } from '@ngt/forms-medical-review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';

interface ISourceDocumentsProps {
}


const sourceDocumentColumns: IGroupedSubformGridCell<SourceDocument>[] = [
    {
        name: 'section',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'type',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'name',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        width: 65,
        name: 'path',
        header: ' ',
        content: (
            <Input component={OpenClinicaFileDisplay} />
        )
    }
];


const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent'
    },
    summary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    subtitle: {
        padding: theme.spacing(0)
    },
    content: {
        display: 'block',
        padding: theme.spacing(0)
    },
    alert: {
        padding: theme.spacing(2)
    }
}));

const formType = {} as any;


const conditionSubscription = { value: true };

const SourceDocuments: FunctionComponent<ISourceDocumentsProps> = () => {
    const {classes} = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        container: {
            borderLeft: 'none',
            borderRight: 'none',
            backgroundColor: 'transparent'
        },
        summary: {
            '& > .MuiAccordionSummary-content': {
                justifyContent: 'space-between'
            },
            '&.Mui-expanded': {
                minHeight: 48,

                '& > div.MuiAccordionSummary-content': {
                    margin: '12px 0'
                }
            }
        },
        subtitle: {
            padding: theme.spacing(0)
        },
        content: {
            display: 'block',
            padding: theme.spacing(0)
        },
        alert: {
            padding: theme.spacing(2)
        }
    }), [theme]);

    const hasSourceDocuments: ICondition<any> = useCallback(({ formState: { value } = {} }) => (value?.sourceDocuments?.length ?? 0) > 0, []);
    const hasNoSourceDocuments: ICondition<any> = useCallback(({ formState: { value } = {} }) => (value?.sourceDocuments?.length ?? 0) === 0, []);

    return (
        <GroupedFields>
            <Accordion sx={styles.container} elevation={0} square>
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={styles.summary}
                >
                    <Typography variant="h2" sx={styles.subtitle}>
                        Source Documents
                    </Typography>
                    <InputOnlyField
                        name="sourceDocuments"
                        component={CountDisplay}
                        entity="Document"
                    />
                </AccordionSummary>
                <AccordionDetails sx={styles.content}>
                    <Condition
                        type={formType}
                        condition={hasSourceDocuments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={SourceDocument}
                            name="sourceDocuments"
                            columns={sourceDocumentColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={formType}
                        condition={hasNoSourceDocuments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Source Documents"
                                description="There are no source documents associated with this report."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </AccordionDetails>
            </Accordion>
        </GroupedFields>
    );
};

export default SourceDocuments;
