import { Box, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { MedicalHistoryAndPriorTreatment as MedicalHistoryAndPriorTreatmentDto, ProtocolDeviation, Comment, MedicalHistory, Chemotherapy, ConcomitantMedication, AntiHer2Therapy, EndocrineTherapy, Radiation, OtherTreatment, SurvivalFollowUp } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';
import { FollowUp as FollowUpDto } from '../../apis/dtos';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;


interface IFollowUpProps {
}


const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2),
        color: theme.palette.secondary.main
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const survivalFollowUpColumns: IGroupedSubformGridCell<SurvivalFollowUp>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        width: 200
    },
    {
        name: 'consultationDate',
        content: (
            <Input component={DateDisplay} />
        ),
    },
    {
        name: 'followUpBy',
        content: (
            <Input component={TextDisplay} />
        ),
    },
    {
        name: 'anyChangesSinceThePreviousAssessment',
        content: (
            <Input component={TextDisplay} />
        ),
    },
    {
        name: 'trastuzumab',
        content: (
            <Input component={TextDisplay} />
        ),
    },
    {
        name: 'endocrine',
        content: (
            <Input component={TextDisplay} />
        ),
    }
];

const hasNoSurvivalFollowUp: ICondition<FollowUpDto> = ({ formState: { value } = {} }) => !value?.survivalFollowUps || value?.survivalFollowUps?.length === 0;;
const hasSurvivalFollowUp: ICondition<FollowUpDto> = ({ formState: { value } = {} }) => !!value?.survivalFollowUps && value?.survivalFollowUps?.length > 0;


const conditionSubscription = { value: true };

const FollowUp: FunctionComponent<IFollowUpProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2),
            color: theme.palette.secondary.main
        },
        titleContainer: {
            padding: theme.spacing(2, 2, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }, 
        subtitle: {
            padding: theme.spacing(2, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        }
    }), [theme])

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        Survival Follow Up 
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedFields>
                    <Condition
                        type={FollowUpDto}
                        condition={hasSurvivalFollowUp}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={SurvivalFollowUp}
                            name="survivalFollowUps"
                            columns={survivalFollowUpColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={FollowUpDto}
                        condition={hasNoSurvivalFollowUp}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Survival Follow Up Forms"
                                description="There is no survival follow up forms associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default FollowUp;
